import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import { confirmLoop, generateLoop } from "../../services/loop";
import { Sliders } from "react-feather";
import { Chip } from "@mui/material";
import PopSnackbar from "../../components/snackbar";
import AlertModal from "../../components/modal";

const LoopPreview = () => {
  const location = useLocation();
  const {
    partners,
    counties,
    cities,
    locations,
    tags,
    timeInterval,
    selectedDevices,
  } = location.state;

  const [loading, setLoading] = useState(false);
  const [toastStatus, setToastStatus] = useState(false);
  const [toastDescription, setToastDescription] = useState("");
  const [data, setData] = useState([]);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const navigate = useNavigate();

  const handleStatus = (description, setStatus, setDescription) => {
    setStatus(true);
    setDescription(description);
    setTimeout(() => {
      setStatus(false);
      setDescription("");
    }, 5000);
  };

  const generateLoopPayload = async () => {
    const loopPayload = {
      partners: partners.map((p) => p.id),
      counties,
      cities,
      locations: locations.map((location) => location.id),
      tags: tags.map((tag) => tag.id),
      timeInterval,
    };

    try {
      setLoading(true);
      const response = await generateLoop(JSON.stringify(loopPayload));
      if (response.status === "success") {
        setData(response);
      } else {
        throw new Error(response.message);
      }
      handleStatus(
        "Generated loop successfully",
        setToastStatus,
        setToastDescription
      );
    } catch (error) {
      handleStatus(
        error.message || "Something went wrong",
        setToastStatus,
        setToastDescription
      );
    } finally {
      setLoading(false);
    }
  };

  const confirmLoopGeneration = () => {
    try {
      setLoading(true);

      const time_interval = data.timeInterval;
      const finalPlaylist = data.finalPlaylist || [];

      const requestBody = {
        finalPlaylist,
        selectedDeviceIDs: selectedDevices.map((d) => d.device_id),
        partners: partners.map((p) => p.id),
        counties,
        cities,
        locations: locations.map((l) => l.id),
        tags: tags.map((t) => t.id),
      };

      confirmLoop(time_interval, requestBody)
        .then((response) => {
          if (response.status === "success") {
            handleStatus(
              "Loop generation confirmed successfully",
              setToastStatus,
              setToastDescription
            );
          } else {
            throw new Error(response.message);
          }
        })
        .catch((error) => {
          handleStatus(
            error.message || "Something went wrong",
            setToastStatus,
            setToastDescription
          );
        })
        .finally(() => {
          setLoading(false);
        });

      navigate(-1);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div>
      {loading ? (
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <CircularProgress />
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            gap: "20px",
            alignItems: "flex-start",
          }}
        >
          <div style={{ flex: 2 }}>
            <button
              style={{
                marginTop: "20px",
                borderRadius: "12px",
                marginBottom: "20px",
                opacity: loading ? 0.5 : 1,
              }}
              className="add-video-button"
              disabled={loading}
              onClick={() => {
                generateLoopPayload();
                setOpenConfirmModal(true);
              }}
            >
              Generate Loop
            </button>

            {!(Array.isArray(data) && data.length === 0) && (
              <AlertModal
                open={openConfirmModal}
                text={
                  <>
                    <p>The percentages for each category are as follows:</p>
                    <p>
                      <span style={{ fontFamily: "SeagoeUIBold" }}>
                        {data.percentage1?.toFixed(2)}%
                      </span>{" "}
                      for campanii publicitare,
                    </p>
                    <p>
                      <span style={{ fontFamily: "SeagoeUIBold" }}>
                        {data.percentage2?.toFixed(2)}%
                      </span>{" "}
                      for spoturi de locatie,
                    </p>
                    <p>
                      <span style={{ fontFamily: "SeagoeUIBold" }}>
                        {data.percentage3?.toFixed(2)}%
                      </span>{" "}
                      for continut informativ,
                    </p>
                    <p>Do you want to continue?</p>
                  </>
                }
                handleYes={confirmLoopGeneration}
                handleClose={() => setOpenConfirmModal(false)}
              />
            )}
          </div>
          <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <Card
              style={{
                padding: "15px",
                boxShadow: "0 4px 12px #0000001a",
                borderRadius: "12px",
              }}
            >
              <CardContent>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    marginBottom: "15px",
                  }}
                >
                  <Sliders size={24} />
                  <label
                    style={{
                      fontSize: "1.75rem",
                      fontFamily: "SeagoeUIBold",
                      color: "#333",
                    }}
                  >
                    Configuration Details
                  </label>
                </div>
                <Divider
                  style={{ margin: "15px 0", backgroundColor: "#e0e0e0" }}
                />

                <div style={{ marginBottom: "20px" }}>
                  <label style={{ fontFamily: "SeagoeUIBold", color: "#555" }}>
                    Partners
                  </label>
                  <div
                    style={{ display: "flex", gap: "8px", marginTop: "8px" }}
                  >
                    {partners && partners.length > 0 ? (
                      partners.map((partner) => (
                        <Chip
                          key={partner.id}
                          label={partner.name}
                          style={{ backgroundColor: "#e0f7fa" }}
                        />
                      ))
                    ) : (
                      <span style={{ color: "#aaa" }}>N/A</span>
                    )}
                  </div>
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <label style={{ fontFamily: "SeagoeUIBold", color: "#555" }}>
                    Counties
                  </label>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      flexWrap: "wrap",
                      marginTop: "8px",
                    }}
                  >
                    {counties.length > 0 ? (
                      counties.map((county, index) => (
                        <Chip
                          key={index}
                          label={county}
                          style={{
                            backgroundColor: "#e0f7fa",
                          }}
                        />
                      ))
                    ) : (
                      <span style={{ color: "#aaa" }}>N/A</span>
                    )}
                  </div>
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <label style={{ fontFamily: "SeagoeUIBold", color: "#555" }}>
                    Cities
                  </label>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      flexWrap: "wrap",
                      marginTop: "8px",
                    }}
                  >
                    {cities.length > 0 ? (
                      cities.map((city, index) => (
                        <Chip
                          key={index}
                          label={city}
                          style={{
                            backgroundColor: "#e0f7fa",
                          }}
                        />
                      ))
                    ) : (
                      <span style={{ color: "#aaa" }}>N/A</span>
                    )}
                  </div>
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <label style={{ fontFamily: "SeagoeUIBold", color: "#555" }}>
                    Locations
                  </label>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      flexWrap: "wrap",
                      marginTop: "8px",
                    }}
                  >
                    {locations.length > 0 ? (
                      locations.map((loc, index) => (
                        <Chip
                          key={index}
                          label={loc.name}
                          style={{
                            backgroundColor: "#e0f7fa",
                          }}
                        />
                      ))
                    ) : (
                      <span style={{ color: "#aaa" }}>N/A</span>
                    )}
                  </div>
                </div>

                <div style={{ marginBottom: "20px" }}>
                  <label style={{ fontFamily: "SeagoeUIBold", color: "#555" }}>
                    Tags
                  </label>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      flexWrap: "wrap",
                      marginTop: "8px",
                    }}
                  >
                    {tags.length > 0 ? (
                      tags.map((tag, index) => (
                        <Chip
                          key={index}
                          label={tag.name}
                          style={{
                            backgroundColor: "#e0f7fa",
                          }}
                        />
                      ))
                    ) : (
                      <span style={{ color: "#aaa" }}>N/A</span>
                    )}
                  </div>
                </div>

                <div>
                  <label style={{ fontFamily: "SeagoeUIBold", color: "#555" }}>
                    Time Interval Selected
                  </label>
                  <div
                    style={{ display: "flex", gap: "8px", marginTop: "8px" }}
                  >
                    {timeInterval ? (
                      <Chip
                        label={`${timeInterval} minutes`}
                        style={{ backgroundColor: "#e0f7fa" }}
                      />
                    ) : (
                      <span style={{ color: "#aaa" }}>N/A</span>
                    )}
                  </div>
                </div>

                <div style={{ marginTop: "8px" }}>
                  <label
                    style={{
                      fontFamily: "SeagoeUIBold",
                      color: "#555",
                    }}
                  >
                    Selected Devices for Playlist
                  </label>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      flexWrap: "wrap",
                      marginTop: "8px",
                    }}
                  >
                    {selectedDevices.length > 0 ? (
                      selectedDevices.map((device, index) => (
                        <Chip
                          key={index}
                          label={`${device.device_id} | ${device.name}`}
                          style={{
                            backgroundColor: "rgba(255, 71, 71, 0.15)",
                            fontFamily: "SeagoeUIBold",
                            color: "#333",
                          }}
                        />
                      ))
                    ) : (
                      <span style={{ color: "#aaa" }}>N/A</span>
                    )}
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      )}
      {toastStatus && <PopSnackbar message={toastDescription} />}
    </div>
  );
};

export default LoopPreview;
