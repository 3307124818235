import { useNavigate } from "react-router-dom";
import { useCallback, useRef, useState } from "react";
import PopSnackbar from "../../components/snackbar";
import { keyframes, styled } from "@mui/material/styles";
import { Tabs, Tab, Box } from "@mui/material";
import CampaignIcon from "@mui/icons-material/Campaign";
import LoopIcon from "@mui/icons-material/Loop";
import { Check } from "react-feather";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import {
  CreateCampaign,
  DeleteCampaign,
  GenerateCampaignPlaylist,
} from "../../services/campaign";
import {
  AddVideoToCampaign,
  DeleteVideoGlobal,
  UploadVideo,
} from "../../services/video";
import AlertModal from "../../components/modal";
import AddLoopStep from "./tabs/add-loop";
import AddCampaignStep from "./tabs/add-campaign";

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledTabs = styled(Tabs)(({ theme }) => ({
  position: "relative",
  width: "100%",
  background: "linear-gradient(135deg, #fafafa, #fff)",
  borderRadius: "8px 8px 0 0",
  boxShadow: "0 1px 1px rgba(0,0,0,0.1)",
  overflow: "hidden",
  "& .MuiTabs-indicator": {
    display: "none",
  },
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontSize: "1rem",
    padding: theme.spacing(2, 1),
    fontFamily: "SeagoeUI, sans-serif",
    color: "rgba(0, 0, 0, 0.65)",
    opacity: 0.85,
    transition: "all 0.3s ease",
    position: "relative",
    width: "50%",
    borderRight: "1px solid rgba(0, 0, 0, 0.05)",
    "&:last-of-type": {
      borderRight: "none",
    },
    "& .MuiTab-iconWrapper": {
      marginRight: theme.spacing(1),
      fontSize: 22,
      transition: "transform 0.3s ease",
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.03)",
      color: "#000",
      opacity: 1,
      "& .MuiTab-iconWrapper": {
        transform: "scale(1.1)",
      },
    },
    "&.Mui-selected": {
      color: "#000",
      opacity: 1,
      fontFamily: "SeagoeUIBold, sans-serif",
    },
  })
);

const StepIndicator = styled("span")(({ active, completed }) => ({
  width: "30px",
  height: "30px",
  borderRadius: "50%",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "12px",
  fontSize: "0.875rem",
  backgroundColor: completed ? "#000" : active ? "rgba(0, 0, 0, 0.1)" : "#fff",
  color: completed ? "#fff" : active ? "#000" : "rgba(0, 0, 0, 0.75)",
  border: `2px solid ${
    completed
      ? "#000"
      : active
      ? "rgba(97, 97, 97, 0.4)"
      : "rgba(97, 97, 97, 0.2)"
  }`,
  transition: "all 0.3s ease",
  transform: active ? "scale(1.1)" : "scale(1)",
}));

const TabPanel = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 2),
  animation: `${slideIn} 0.5s cubic-bezier(0.4, 0, 0.2, 1)`,
  "& > *": {
    animation: `${slideIn} 0.5s cubic-bezier(0.4, 0, 0.2, 1)`,
  },
}));

const ProgressArrow = styled("div")(({ activeTab }) => ({
  position: "absolute",
  left: activeTab === 0 ? "25%" : "75%",
  transform: "translateX(-50%)",
  width: 0,
  height: 0,
  borderLeft: "10px solid transparent",
  borderRight: "10px solid transparent",
  borderTop: "10px solid #ff4757",
  transition: "left 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
  "&::before, &::after": {
    content: '""',
    position: "absolute",
    width: "4px",
    height: "4px",
    borderRadius: "50%",
    background: "#ff4757",
    animation: "particles 1.5s infinite",
    opacity: 0,
  },
  "&::before": {
    animationDelay: "0.2s",
    left: "-15px",
  },
  "&::after": {
    animationDelay: "0.4s",
    right: "-15px",
  },
  "@keyframes particles": {
    "0%": {
      transform: "translateY(0) scale(1)",
      opacity: 0,
    },
    "50%": {
      transform: "translateY(-20px) scale(0.5)",
      opacity: 0.8,
    },
    "100%": {
      transform: "translateY(-40px) scale(0)",
      opacity: 0,
    },
  },
}));

export default function AddCampaign() {
  const navigate = useNavigate();
  const campaignFormikRef = useRef(null);
  const loopStepRef = useRef(null);

  const [activeTab, setActiveTab] = useState(0);

  const [campaignData, setCampaignData] = useState({
    name: "",
    client: "",
    calendars: [
      {
        id: uuidv4(),
        dateRange: {
          startDate: moment().toDate(),
          endDate: moment().toDate(),
          key: "selection",
        },
        enableStartDate: true, // <--- ensure defaults
        enableEndDate: true, // <--- ensure defaults
      },
    ],
    selectedVideos: [],
    videoFrequency: [],
  });

  const [loopsData, setLoopsData] = useState({
    selectedDevices: [],
    filters: {
      partners: [],
      counties: [],
      cities: [],
      locations: [],
      tags: [],
      search: "",
    },
    timeInterval: 60,
  });
  const [openModal, setOpenModal] = useState(false);
  const [alertModalText, setAlertModalText] = useState("");
  const [toastStatus, setToastStatus] = useState(false);
  const [toastDescription, setToastDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [createdCampaignID, setCreatedCampaignID] = useState(null);

  const handleTabChange = async (_, newTab) => {
    try {
      if (activeTab === 0 && newTab === 1) {
        if (campaignFormikRef.current)
          await campaignFormikRef.current.submitForm();

        const { name, client } = campaignFormikRef.current?.values || {};
        if (!name?.trim() || !client?.trim()) {
          handleStatus(
            "Campaign name and client name are required.",
            setToastStatus,
            setToastDescription
          );
          return;
        }
        if (
          !campaignData.selectedVideos ||
          campaignData.selectedVideos.length === 0
        ) {
          handleStatus(
            "Please select at least one video before proceeding.",
            setToastStatus,
            setToastDescription
          );
          return;
        }
      } else if (
        activeTab === 1 &&
        newTab === 0 &&
        loopStepRef.current?.saveLoopData
      ) {
        await loopStepRef.current.saveLoopData();
      }
      setActiveTab(newTab);
    } catch (error) {
      handleStatus(
        error.message || "Something went wrong",
        setToastStatus,
        setToastDescription
      );
    }
  };

  const handleCampaignChange = useCallback(
    (newCampaignData) =>
      setCampaignData((prev) => ({ ...prev, ...newCampaignData })),
    []
  );

  const handleLoopsChange = useCallback(
    (updatedValues) => setLoopsData((prev) => ({ ...prev, ...updatedValues })),
    []
  );

  const handleStatus = (description) => {
    setToastStatus(true);
    setToastDescription(description);
    setTimeout(() => {
      setToastStatus(false);
      setToastDescription("");
    }, 5000);
  };

  const handleSubmitAll = async () => {
    if (loading) return;

    setLoading(true);

    let createdCampaign = null;
    const newlyCreatedVideoIds = [];
    let shouldNavigate = true;

    try {
      let latestLoopData = loopsData;
      if (loopStepRef.current?.saveLoopData) {
        latestLoopData = await loopStepRef.current.saveLoopData();
        setLoopsData(latestLoopData);
      }

      if (!campaignData.name.trim() || !campaignData.client.trim()) {
        handleStatus("Campaign name and client name are required.");
        setLoading(false);
        return;
      }

      const { name, client, calendars, selectedVideos, videoFrequency } =
        campaignData;

      // 1) Create the campaign
      const campaignPayload = {
        name,
        client,
        schedules: calendars.map((cal) => ({
          startDate: cal.dateRange.startDate
            ? cal.dateRange.startDate.toISOString()
            : null,
          endDate: cal.dateRange.endDate
            ? cal.dateRange.endDate.toISOString()
            : null,
        })),
        devices: latestLoopData.selectedDevices.map((d) => d.device_id),
      };

      const campaignResponse = await CreateCampaign(
        JSON.stringify(campaignPayload)
      );
      if (campaignResponse.status !== "OK") {
        throw new Error(campaignResponse.error || "Failed to create campaign");
      }
      createdCampaign = campaignResponse.data;
      setCreatedCampaignID(createdCampaign.ID);
      // 2) Link/upload videos
      for (let i = 0; i < selectedVideos.length; i++) {
        const video = selectedVideos[i];
        const frequency = videoFrequency[i] || 1;

        // Existing video linking
        if (video.id || video.ID) {
          const linkResponse = await AddVideoToCampaign(
            video.id || video.ID,
            createdCampaign.ID,
            frequency
          );
          if (linkResponse.status !== "OK") {
            throw new Error(
              linkResponse.error || "Failed to link video to campaign"
            );
          }
        } else if (video.file) {
          const videoData = new FormData();
          videoData.append("file", video.file);
          videoData.append("title", video.title);
          videoData.append("duration", video.duration);
          videoData.append("is_landscape", video.is_landscape);
          videoData.append(
            "campaign_ids",
            JSON.stringify([createdCampaign.ID])
          );

          const uploadResponse = await UploadVideo(videoData);
          if (uploadResponse.status !== "OK") {
            throw new Error(uploadResponse.error || "Failed to upload video");
          }

          const uploadedVideoID = uploadResponse.data?.ID;
          newlyCreatedVideoIds.push(uploadedVideoID);

          const linkResponse = await AddVideoToCampaign(
            uploadedVideoID,
            createdCampaign.ID,
            frequency
          );
          if (linkResponse.status !== "OK") {
            throw new Error(
              linkResponse.error || "Failed to link video to campaign"
            );
          }
        }
      }

      let generateResponse;
      try {
        generateResponse = await GenerateCampaignPlaylist(createdCampaign.ID);
        if (
          generateResponse.status === "OK" &&
          generateResponse.regenerationTriggered
        ) {
          setAlertModalText("Playlist is generating for selected devices.");
          setOpenModal(true);
          shouldNavigate = false;

          setTimeout(() => {
            setOpenModal(false);
            navigate(`/devices?campaign_id=${createdCampaign.ID}`);
          }, 2500);

          return;
        }
      } catch (genError) {
        handleStatus(genError.message, setToastStatus, setToastDescription);
        console.error("Failed to generate playlist:", genError);
      }

      // If we get here, either regeneration wasn't triggered or it wasn't needed
      navigate(`/devices?campaign_id=${createdCampaign.ID}`);
    } catch (error) {
      // If something goes wrong, delete the campaign if it was created
      if (createdCampaign?.ID) {
        await DeleteCampaign(createdCampaign.ID, false);
      }
      // And delete any newly uploaded videos
      for (const vidID of newlyCreatedVideoIds) {
        await DeleteVideoGlobal(vidID);
      }
      handleStatus(error.message || "Something went wrong");
    } finally {
      setLoading(false);
      if (shouldNavigate && createdCampaign?.ID) {
        navigate(`/devices?campaign_id=${createdCampaign.ID}`);
      }
    }
  };

  return (
    <div>
      {toastStatus && <PopSnackbar message={toastDescription} />}
      <StyledTabs
        value={activeTab}
        onChange={handleTabChange}
        variant="fullWidth"
      >
        <StyledTab
          icon={
            <div style={{ display: "flex", alignItems: "center" }}>
              <StepIndicator active={activeTab === 0} completed={activeTab > 0}>
                {activeTab > 0 ? <Check fontSize="small" /> : "1"}
              </StepIndicator>
              <CampaignIcon />
            </div>
          }
          label="Campaign Setup"
          iconPosition="start"
        />
        <StyledTab
          icon={
            <div style={{ display: "flex", alignItems: "center" }}>
              <StepIndicator active={activeTab === 1} completed={activeTab > 1}>
                2
              </StepIndicator>
              <LoopIcon />
            </div>
          }
          label="Choose devices"
          iconPosition="start"
        />
        <ProgressArrow activeTab={activeTab} />
      </StyledTabs>

      <TabPanel hidden={activeTab !== 0}>
        <AddCampaignStep
          data={campaignData}
          onChange={handleCampaignChange}
          handleStatus={handleStatus}
          goNext={() => setActiveTab(1)}
          formikRef={campaignFormikRef}
        />
      </TabPanel>

      <TabPanel hidden={activeTab !== 1}>
        <AddLoopStep
          data={loopsData}
          onChange={handleLoopsChange}
          goBack={() => setActiveTab(0)}
          campaignData={campaignData}
          handleStatus={handleStatus}
          onSubmitAll={handleSubmitAll}
          loadingbtn={loading}
          ref={loopStepRef}
        />
      </TabPanel>
      <AlertModal
        open={openModal}
        text={alertModalText}
        handleClose={() => {
          setOpenModal(false);
          navigate(`/devices?campaign_id=${createdCampaignID}`);
        }}
        showYesButton={false}
        showNoButton={false}
        showOkButton={true}
      />
    </div>
  );
}
