import {
  Autocomplete,
  TextField,
  CircularProgress,
  Checkbox,
  Tooltip,
  Backdrop,
} from "@mui/material";
import CustomTable from "../../../components/table";
import {
  downloadLoop,
  fetchCities,
  fetchCounties,
  fetchDevices,
  fetchLocations,
  fetchPartners,
  fetchTags,
} from "../../../services/loop";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import useDebounce from "../../../hooks/debounce";
import PopSnackbar from "../../../components/snackbar";
import { Eye } from "react-feather";
import { VideoPreview } from "../../../components/modal/videopreview";

const styles = {
  select: {
    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
      backgroundColor: "#f9f9f9",
    },
    "& .MuiInputLabel-root": { color: "#555" },
    "&.Mui-disabled .MuiOutlinedInput-root": { backgroundColor: "#f0f0f0" },
    "& .MuiSelect-outlined": {
      borderRadius: "12px",
      backgroundColor: "#f9f9f9",
    },
  },
};

function FilterAutocomplete({
  label,
  options,
  onChange,
  value,
  getOptionLabel,
  loading,
  multiple = true,
}) {
  return (
    <Autocomplete
      sx={styles.select}
      multiple={multiple}
      options={options}
      value={value}
      onChange={onChange}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            label={loading ? <CircularProgress size={20} /> : label}
          />
        </>
      )}
    />
  );
}

const EditLoopStep = forwardRef(
  (
    {
      data,
      campaignData,
      onChange,
      goBack,
      onSubmitAll,
      loadingbtn,
      handleStatus,
    },
    ref
  ) => {
    const {
      selectedDevices: initialSelectedDevices = [],
      filters: initialFilters = {},
      timeInterval: initialTimeInterval = 60,
    } = data;

    const columns = [
      {
        field: "selected_devices",
        headerName: "Selected",
        headerAlign: "center",
        sortable: false,
        renderCell: (params) => (
          <Checkbox
            color="black"
            checked={selectedDevices.some(
              (device) => device.device_id === params.row.device_id
            )}
            onChange={(event) =>
              handleDeviceSelect(
                params.row.device_id,
                params.row.name,
                event.target.checked
              )
            }
          />
        ),
        width: 75,
      },
      {
        field: "device_id",
        headerName: "Device ID",
        headerAlign: "center",
        sortable: false,
        renderCell: (params) => {
          return params.row.device_id;
        },
      },
      {
        field: "device_name",
        headerName: "Device Name",
        headerAlign: "center",
        sortable: false,
        renderCell: (params) => {
          return params.row.name;
        },
      },
      {
        field: "partner",
        headerName: "Partner",
        sortable: false,
        headerAlign: "center",
        renderCell: (params) => {
          return params.row?.location?.partner?.name;
        },
      },
      {
        field: "location",
        headerName: "Location",
        sortable: false,
        headerAlign: "center",
        renderCell: (params) => {
          return params.row?.location?.name;
        },
      },
      {
        field: "county",
        headerName: "County",
        sortable: false,
        headerAlign: "center",
        renderCell: (params) => {
          return params.row?.location?.county;
        },
      },
      {
        field: "city",
        headerName: "City",
        sortable: false,
        headerAlign: "center",
        renderCell: (params) => {
          return params.row?.location?.city;
        },
      },
    ];

    const [devices, setDevices] = useState([]);
    const [partners, setPartners] = useState([]);
    const [tags, setTags] = useState([]);
    const [hierarchicalData, setHierarchicalData] = useState({
      counties: [],
      cities: {},
      locations: {},
    });

    const [loadingFilters, setLoadingFilters] = useState({
      partners: false,
      counties: false,
      cities: false,
      locations: false,
      tags: false,
    });

    const [loading, setLoading] = useState(true);

    const [selectedDevices, setSelectedDevices] = useState(
      initialSelectedDevices
    );

    const [timeInterval, setTimeInterval] = useState(initialTimeInterval);

    const [filters, setFilters] = useState(initialFilters);

    const [page, setPage] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    const [inputText, setInputText] = useState("");
    const debouncedSearchTerm = useDebounce(inputText, 500);

    const [toastStatus, setToastStatus] = useState(false);
    const [toastDescription, setToastDescription] = useState("");

    const [loopPreviewLoading, setLoopPreviewLoading] = useState(false);

    const isCampaignValid = Boolean(
      campaignData?.name?.trim() &&
        campaignData?.client?.trim() &&
        selectedDevices.length > 0
    );

    useImperativeHandle(ref, () => ({
      saveLoopData: async () => {
        const updatedData = {
          selectedDevices,
          filters,
          timeInterval,
        };
        onChange(updatedData);
        return updatedData;
      },
    }));

    const handleDownloadLoop = async (loopID) => {
      try {
        setLoopPreviewLoading(true);

        const { url } = await downloadLoop(loopID);

        VideoPreview({
          url,
          onClose: () => {},
        });
      } catch (error) {
        handleStatus(error.message, setToastStatus, setToastDescription);
      } finally {
        setLoopPreviewLoading(false);
      }
    };

    const fetchOptions = useCallback(async (filterType) => {
      try {
        switch (filterType) {
          case "partners": {
            setLoadingFilters((prev) => ({ ...prev, partners: true }));
            const partnersResponse = await fetchPartners();
            setPartners(partnersResponse.data);
            setLoadingFilters((prev) => ({ ...prev, partners: false }));
            break;
          }
          case "tags": {
            setLoadingFilters((prev) => ({ ...prev, tags: true }));
            const tagsResponse = await fetchTags();
            setTags(tagsResponse.data);
            setLoadingFilters((prev) => ({ ...prev, tags: false }));
            break;
          }
          case "counties": {
            setLoadingFilters((prev) => ({ ...prev, counties: true }));
            const countiesResponse = await fetchCounties();
            setHierarchicalData((prev) => ({
              ...prev,
              counties: countiesResponse.data,
            }));
            setLoadingFilters((prev) => ({ ...prev, counties: false }));
            break;
          }
          case "cities": {
            setLoadingFilters((prev) => ({ ...prev, cities: true }));
            const citiesResponse = await fetchCities();
            setHierarchicalData((prev) => ({
              ...prev,
              cities: citiesResponse.data,
            }));
            setLoadingFilters((prev) => ({ ...prev, cities: false }));
            break;
          }
          case "locations": {
            setLoadingFilters((prev) => ({ ...prev, locations: true }));
            const locationsResponse = await fetchLocations();
            setHierarchicalData((prev) => ({
              ...prev,
              locations: locationsResponse.data,
            }));
            setLoadingFilters((prev) => ({ ...prev, locations: false }));
            break;
          }
          default:
            break;
        }
      } catch (error) {
        handleStatus(error.message, setToastStatus, setToastDescription);
      }
    }, []);

    const fetchFilteredDevices = async (
      pageNumber = 1,
      isLoading = true,
      updatedFilters = filters
    ) => {
      try {
        if (isLoading) setLoading(true);

        const response = await fetchDevices(updatedFilters, pageNumber);
        if (response.status !== "OK") {
          throw new Error(
            response.error || "Something went wrong, please try again later."
          );
        }

        setTotalCount(response.totalCount);
        setDevices(response.data);

        setSelectedDevices((prevSelected) => {
          const newDeviceIds = response.data.map((d) => d.device_id);
          return prevSelected.filter((sel) =>
            newDeviceIds.includes(sel.device_id)
          );
        });
      } catch (error) {
        handleStatus(error.message, setToastStatus, setToastDescription);
      } finally {
        if (isLoading) setLoading(false);
      }
    };

    const updateFilters = (newFilter) => {
      const updated = { ...filters, ...newFilter };
      setFilters(updated);
      fetchFilteredDevices(1, true, updated);
    };

    const handlePartnerChange = (event, selectedPartners) => {
      const partnerIds = selectedPartners.map((partner) => partner.ID);
      updateFilters({ partners: partnerIds });
    };
    const handleCountyChange = (event, selectedCounties) => {
      const countyNames = selectedCounties.map((c) => c);
      updateFilters({ counties: countyNames });
    };
    const handleCityChange = (event, selectedCities) => {
      const cityNames = selectedCities.map((c) => c);
      updateFilters({ cities: cityNames });
    };
    const handleLocationChange = (event, selectedLocations) => {
      const locationIds = selectedLocations.map((loc) => loc.ID);
      updateFilters({ locations: locationIds });
    };
    const handleTagChange = (event, selectedTags) => {
      const tagIds = selectedTags.map((t) => t.ID);
      updateFilters({ tags: tagIds });
    };

    const handleDeviceSelect = (deviceId, deviceName, checked) => {
      if (checked) {
        setSelectedDevices((prev) => [
          ...prev,
          { device_id: deviceId, name: deviceName },
        ]);
      } else {
        setSelectedDevices((prev) =>
          prev.filter((dev) => dev.device_id !== deviceId)
        );
      }
    };

    const handleChangePage = async (event, newPage) => {
      const updatedFilters = { ...filters, search: debouncedSearchTerm };
      await fetchFilteredDevices(newPage + 1, true, updatedFilters);
      setPage(newPage);
      setFilters(updatedFilters);
    };

    const handleSaveAndSubmit = async () => {
      if (!isCampaignValid) {
        handleStatus(
          "Campaign name and client are required before saving the loop.",
          setToastStatus,
          setToastDescription
        );
        return;
      }

      const updatedLoopData = ref.current
        ? await ref.current.saveLoopData()
        : {};

      if (onSubmitAll) {
        onSubmitAll(updatedLoopData);
      }
    };

    const handleSelectAllDevices = () => {
      const allDevices = devices.map((dev) => ({
        device_id: dev.device_id,
        name: dev.name,
      }));

      setSelectedDevices(allDevices);
    };

    const handleDeselectAllDevices = () => {
      setSelectedDevices([]);
    };

    useEffect(() => {
      if (debouncedSearchTerm.length < 3 && debouncedSearchTerm.length > 0) {
        return;
      }
      const updated = { ...filters, search: debouncedSearchTerm };
      setPage(0);
      fetchFilteredDevices(1, true, updated);
      setFilters(updated);
    }, [debouncedSearchTerm]);

    useEffect(() => {
      fetchFilteredDevices(1, true, filters);
    }, []);

    useEffect(() => {
      fetchOptions("partners");
      fetchOptions("tags");
      fetchOptions("counties");
      fetchOptions("cities");
      fetchOptions("locations");
      fetchFilteredDevices(1, true, initialFilters);
    }, []);

    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {toastStatus && <PopSnackbar message={toastDescription} />}
        <div
          style={{ display: "flex", alignItems: "center", alignSelf: "center" }}
        >
          <TextField
            sx={styles.select}
            style={{
              width: "7vw",
              marginBottom: 30,
            }}
            type="number"
            value={timeInterval || 60}
            onChange={(e) => {
              const value = e.target.value;

              if (/^\d*$/.test(value)) {
                const numericValue = value === "" ? "" : parseInt(value, 10);

                if (numericValue === "" || numericValue <= 60) {
                  setTimeInterval(numericValue);
                }
              }
            }}
            inputProps={{
              min: 1,
              max: 60,
              step: 1,
              style: { textAlign: "center" },
            }}
            placeholder="Minutes"
          />
        </div>

        <div style={{ display: "flex", gap: 40 }}>
          <div
            style={{
              flex: "0 0 20%",
              minWidth: "200px",
              display: "flex",
              flexDirection: "column",
              rowGap: "30px",
              boxSizing: "border-box",
            }}
          >
            <FilterAutocomplete
              label="Select Partner"
              options={partners}
              value={
                partners.filter((p) => filters.partners.includes(p.ID)) || []
              }
              onChange={handlePartnerChange}
              getOptionLabel={(option) => option?.name}
              isOptionEqualToValue={(option, value) => option.ID === value.ID}
              loading={loadingFilters.partners}
              multiple={true}
            />
            <FilterAutocomplete
              label="Select County(s)"
              options={hierarchicalData.counties || []}
              value={filters.counties || []}
              onChange={handleCountyChange}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) => option === value}
              loading={loadingFilters.counties}
            />
            <FilterAutocomplete
              label="Select City(s)"
              options={Object.values(hierarchicalData.cities) || []}
              value={filters.cities || []}
              onChange={handleCityChange}
              getOptionLabel={(option) => option}
              isOptionEqualToValue={(option, value) => option === value}
              loading={loadingFilters.cities}
            />
            <FilterAutocomplete
              label="Select Location(s)"
              options={Object.values(hierarchicalData.locations) || []}
              value={Object.values(hierarchicalData.locations).filter((loc) =>
                filters.locations?.includes(loc.ID)
              )}
              onChange={handleLocationChange}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.ID === value.ID}
              loading={loadingFilters.locations}
            />
            <FilterAutocomplete
              label="Select Tag(s)"
              options={tags}
              value={tags.filter((t) => filters.tags?.includes(t.ID))}
              onChange={handleTagChange}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.ID === value.ID}
              loading={loadingFilters.tags}
            />
          </div>

          <div style={{ flex: 2 }}>
            <CustomTable
              search={true}
              columns={columns}
              tableLoading={loading}
              inputText={inputText}
              setInputText={setInputText}
              data={devices}
              page={page}
              handleChangePage={handleChangePage}
              totalCount={totalCount}
              addNew={true}
              addNewTitle={"Select all devices"}
              addNewFunction={handleSelectAllDevices}
              secondAddNew={true}
              secondAddNewTitle={"Deselect all devices"}
              secondAddNewFunction={handleDeselectAllDevices}
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            width: "100%",
            columnGap: 10,
            marginTop: 60,
            alignSelf: "center",
          }}
        >
          <button
            type="button"
            className="add-video-button"
            onClick={() => {
              if (ref?.current?.saveLoopData) {
                ref.current.saveLoopData();
              }
              goBack();
            }}
          >
            ← Back to campaign setup
          </button>

          <button
            type="button"
            className="add-video-button"
            onClick={handleSaveAndSubmit}
            disabled={!isCampaignValid || loadingbtn}
            style={{
              opacity: !isCampaignValid || loadingbtn ? 0.6 : 1,
              cursor:
                !isCampaignValid || loadingbtn ? "not-allowed" : "pointer",
            }}
          >
            {loadingbtn ? (
              <CircularProgress
                size={20}
                style={{ alignSelf: "center" }}
                color="black"
              />
            ) : (
              "Save changes & Update loop →"
            )}
          </button>
        </div>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loopPreviewLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
);

export default EditLoopStep;
