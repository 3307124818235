import fetcher from "../helpers/fetcher";

export async function GetAllDevices(
  page,
  searchTerm = "",
  locationId = "",
  campaginID = ""
) {
  let path = `/devices?page=${page}&search=${searchTerm}&location_id=${locationId}&campaign_id=${campaginID}`;

  let request = await fetcher(path, "GET");

  let content = request.json();

  return content;
}

export async function GetVideosByDeviceID(device_id, page, searchTerm = "") {
  let path = `/videos/device/${device_id}?page=${page}&search=${searchTerm}`;

  let request = await fetcher(path, "GET");

  let content = request.json();

  return content;
}

export async function GetAllDeviceNames() {
  let path = "/all-device-names";

  let request = await fetcher(path, "GET");

  let content = await request.json();

  return content;
}

export async function UpdateDeviceName(body, device_id) {
  let path = `/devices/update-name/${device_id}`;

  let request = await fetcher(path, "PUT", body);

  let content = await request.json();

  return content;
}

export async function AddOrUpdateDeviceObservations(body, device_id) {
  let path = `/devices/observations/${device_id}`;

  let request = await fetcher(path, "PUT", body);

  let content = await request.json();

  return content;
}

export async function UpdateDeviceWorkSchedule(body, device_id) {
  let path = `/devices/update-work-schedule/${device_id}`;

  let request = await fetcher(path, "PUT", body);

  let content = await request.json();

  return content;
}

export async function SendDataForLoop(body) {
  let path = "/devices/save-loop";

  let request = await fetcher(path, "POST", body);

  let content = await request.json();

  return content;
}

export async function HandleToggleTV(device_id, isTVOff) {
  const path = `/devices/tv-command/${device_id}`;
  const command = isTVOff ? "tv_on" : "tv_off";
  const body = JSON.stringify({ command });

  let response = await fetcher(path, "POST", body);
  let content = await response.json();
  return content;
}
