import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  TextField,
  Tooltip,
} from "@mui/material";
import { Formik, Form } from "formik";
import * as yup from "yup";
import { ChevronLeft, ChevronRight, MinusSquare } from "react-feather";
import { v4 as uuidv4 } from "uuid";
import moment from "moment/moment";
import CustomButton from "../../../components/button";
import AddVideoDialog from "../../../components/modal/videodialog";
import AlertModal from "../../../components/modal";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

const validationSchema = yup.object().shape({
  name: yup.string().required("Campaign name is required"),
  client: yup.string().required("Client name is required"),
});

const PinkSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#ff4757",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#ff4757",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#ff4757",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));

const styles = {
  select: {
    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
      backgroundColor: "#f9f9f9",
    },
    "& .MuiInputLabel-root": { color: "#555" },
    "&.Mui-disabled .MuiOutlinedInput-root": { backgroundColor: "#f0f0f0" },
  },
  container: {
    display: "flex",
    gap: "40px",
    width: "100%",
    height: "100%",
  },
  leftSection: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  rightSection: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    alignItems: "center",
  },
  videoList: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
  },
  videoItem: {
    padding: "10px",
    backgroundColor: "#f9f9f9",
    border: "1px solid #ccc",
    borderRadius: "4px",
    fontSize: "1rem",
    fontFamily: "SeagoeUI",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  videoTitle: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
};

export default function AddCampaignStep({
  data = {},
  onChange = () => {},
  goNext = () => {},
  handleStatus = () => {},
  formikRef,
}) {
  const {
    name = "",
    client = "",
    observations = "",
    calendars: initialCalendars = [
      {
        id: uuidv4(),
        dateRange: {
          startDate: moment().toDate(),
          endDate: moment().toDate(),
          key: "selection",
        },
        enableStartDate: true,
        enableEndDate: true,
      },
    ],
    selectedVideos: initialSelectedVideos = [],
    videoFrequency: initialVideoFrequency = [],
  } = data;

  const [calendars, setCalendars] = useState(initialCalendars);
  const [currentCalendarIndex, setCurrentCalendarIndex] = useState(0);
  const [selectedVideos, setSelectedVideos] = useState(initialSelectedVideos);
  const [videoFrequency, setVideoFrequency] = useState(initialVideoFrequency);
  const [manualSelection, setManualSelection] = useState(false);
  const [publishNow, setPublishNow] = useState(false);
  const [campaignNeverEnds, setCampaignNeverEnds] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [toastStatus, setToastStatus] = useState(false);
  const [toastDescription, setToastDescription] = useState("");
  const [alertDeleteCalendar, setAlertDeleteCalendar] = useState(false);
  const [calendarToRemove, setCalendarToRemove] = useState(null);
  const [tempDateValues, setTempDateValues] = useState(
    calendars.map((cal) => ({
      startDate: cal.enableStartDate
        ? moment(cal.dateRange.startDate).format("YYYY-MM-DD")
        : "",
      endDate: cal.enableEndDate
        ? moment(cal.dateRange.endDate).format("YYYY-MM-DD")
        : "",
    }))
  );

  useEffect(() => {
    const firstStart = calendars[0]?.dateRange?.startDate;
    if (firstStart && moment(firstStart).isSame(moment(), "day")) {
      setPublishNow(true);
    }
  }, []);

  useEffect(() => {
    onChange({
      name,
      client,
      observations,
      calendars,
      selectedVideos,
      videoFrequency,
    });
  }, [
    name,
    client,
    observations,
    calendars,
    selectedVideos,
    videoFrequency,
    onChange,
  ]);

  const handleFormikSubmit = (values) => {
    if (!selectedVideos || selectedVideos.length === 0) {
      handleStatus(
        "Please select at least one video before proceeding.",
        setToastStatus,
        setToastDescription
      );
      return;
    }
    onChange({
      name: values.name,
      client: values.client,
      observations: values.observations,
      calendars,
      selectedVideos,
      videoFrequency,
    });
    goNext();
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleDateInputChange = (index, field, value) => {
    // Just update the local text field state
    setTempDateValues((prev) =>
      prev.map((item, i) => (i === index ? { ...item, [field]: value } : item))
    );
  };

  const revertTempDate = (index, field) => {
    // If that date is disabled, revert to empty string
    if (
      (field === "startDate" && !calendars[index].enableStartDate) ||
      (field === "endDate" && !calendars[index].enableEndDate)
    ) {
      setTempDateValues((prev) =>
        prev.map((item, i) => {
          if (i !== index) return item;
          return { ...item, [field]: "" };
        })
      );
      return;
    }

    // Otherwise revert to what's in the calendars array
    setTempDateValues((prev) =>
      prev.map((item, i) => {
        if (i !== index) return item;
        return {
          ...item,
          [field]: moment(calendars[index].dateRange[field]).format(
            "YYYY-MM-DD"
          ),
        };
      })
    );
  };

  const applyCampaignNowRules = (index, nextCalendars, nextTempVals) => {
    const cal = nextCalendars[index];
    if (!cal) return;

    const startEnabled = cal.enableStartDate;
    const endEnabled = cal.enableEndDate;

    // (1) If end date is enabled but start not => force start = today
    if (endEnabled && !startEnabled) {
      cal.enableStartDate = true;
      cal.dateRange.startDate = moment().toDate();
      if (nextTempVals[index]) {
        nextTempVals[index].startDate = moment().format("YYYY-MM-DD");
      }
    }
    // (2) If start is enabled & exactly "today"
    // We'll do nothing special here beyond acknowledging it's 'today.'
    // Because you might want to ensure publishNow = true if *any* calendar is "today."
    // (3) If both are disabled => force start = today
    else if (!startEnabled && !endEnabled) {
      cal.enableStartDate = true;
      cal.dateRange.startDate = moment().toDate();
      if (nextTempVals[index]) {
        nextTempVals[index].startDate = moment().format("YYYY-MM-DD");
      }
    }

    // Now let's figure out publishNow:
    //  - If *any* calendar is "today," we want publishNow = true.
    //  - If *all* calendars are future, we want publishNow = false.

    // Re-check all calendars after our modifications.
    const anyToday = nextCalendars.some((c) => {
      if (!c.enableStartDate || !c.dateRange.startDate) return false;
      return moment(c.dateRange.startDate).isSame(moment(), "day");
    });

    const allFuture = nextCalendars.every((c) => {
      // If start date is missing or disabled, we can't call it "future"
      // but you could decide to treat "null" as future or not.
      // We'll treat "null" as not future to avoid forcibly disabling publishNow.
      if (!c.enableStartDate || !c.dateRange.startDate) return false;
      return moment(c.dateRange.startDate).isAfter(moment(), "day");
    });

    if (anyToday) {
      // setPublishNow(true);
    } else if (allFuture) {
      setPublishNow(false);
    }

    return { nextCalendars, nextTempVals };
  };

  const handleDateInputBlur = (index, field, value) => {
    // If that date is disabled, revert to ""
    if (
      (field === "startDate" && !calendars[index].enableStartDate) ||
      (field === "endDate" && !calendars[index].enableEndDate)
    ) {
      revertTempDate(index, field);
      return;
    }

    const newDate = moment(value, "YYYY-MM-DD", true);
    const today = moment().startOf("day");

    if (!newDate.isValid()) {
      handleStatus(
        "Please enter a valid date.",
        setToastStatus,
        setToastDescription
      );
      revertTempDate(index, field);
      return;
    }
    if (newDate.isBefore(today, "day")) {
      handleStatus(
        "You cannot select a date before today.",
        setToastStatus,
        setToastDescription
      );
      revertTempDate(index, field);
      return;
    }

    setCalendars((prev) => {
      const next = [...prev];
      const cal = { ...next[index] };
      const { dateRange } = cal;

      let newStart = dateRange.startDate;
      let newEnd = dateRange.endDate;

      if (field === "startDate") {
        newStart = newDate.toDate();
        if (newEnd && moment(newStart).isAfter(moment(newEnd), "day")) {
          handleStatus(
            "Start date cannot be after the end date.",
            setToastStatus,
            setToastDescription
          );
          revertTempDate(index, field);
          return prev;
        }
        cal.dateRange = {
          ...dateRange,
          startDate: newStart,
        };
      } else {
        newEnd = newDate.toDate();
        if (newStart && moment(newEnd).isBefore(moment(newStart), "day")) {
          handleStatus(
            "End date cannot be before the start date.",
            setToastStatus,
            setToastDescription
          );
          revertTempDate(index, field);
          return prev;
        }
        cal.dateRange = {
          ...dateRange,
          endDate: newEnd,
        };
      }

      next[index] = cal;

      // After user picks a date, apply the new rules
      const nextTempVals = [...tempDateValues];
      const result = applyCampaignNowRules(index, next, nextTempVals);
      if (result) {
        return result.nextCalendars;
      }
      return next;
    });
  };

  const handleToggleStartDate = (index, isChecked) => {
    setCalendars((prev) => {
      const next = [...prev];
      const cal = { ...next[index] };

      if (!isChecked) {
        cal.enableStartDate = false;
        cal.dateRange = {
          ...cal.dateRange,
          startDate: null,
        };
      } else {
        // Re-enabling => use typed value or fallback to today
        const fallback = tempDateValues[index].startDate
          ? moment(tempDateValues[index].startDate, "YYYY-MM-DD", true)
          : moment();
        cal.enableStartDate = true;
        cal.dateRange = {
          ...cal.dateRange,
          startDate: fallback.isValid() ? fallback.toDate() : moment().toDate(),
        };
      }
      next[index] = cal;

      // Now apply the new rules
      const nextTempVals = [...tempDateValues];
      const result = applyCampaignNowRules(index, next, nextTempVals);
      if (result) {
        // Also reflect new start date in temp
        setTempDateValues(result.nextTempVals);
        return result.nextCalendars;
      }
      return next;
    });
  };

  const handleToggleEndDate = (index, isChecked) => {
    setCalendars((prev) => {
      const next = [...prev];
      const cal = { ...next[index] };

      if (!isChecked) {
        cal.enableEndDate = false;
        cal.dateRange = {
          ...cal.dateRange,
          endDate: null,
        };
      } else {
        // Re-enable with existing or fallback
        const fallback = tempDateValues[index].endDate
          ? moment(tempDateValues[index].endDate, "YYYY-MM-DD", true)
          : moment();
        cal.enableEndDate = true;
        cal.dateRange = {
          ...cal.dateRange,
          endDate: fallback.isValid() ? fallback.toDate() : moment().toDate(),
        };
      }
      next[index] = cal;

      // Now apply the new rules
      const nextTempVals = [...tempDateValues];
      const result = applyCampaignNowRules(index, next, nextTempVals);
      if (result) {
        // Also reflect new end date in temp
        setTempDateValues(result.nextTempVals);
        return result.nextCalendars;
      }
      return next;
    });
  };

  const handleManualSelectionChange = (event) => {
    const newValue = event.target.checked;

    if (newValue) {
      // If turning ON multi-range
      const allCalendarsHaveBoth = calendars.every(
        (cal) => cal.enableStartDate && cal.enableEndDate
      );
      if (!allCalendarsHaveBoth) {
        handleStatus(
          "Cannot enable multiple date ranges because at least one calendar has a disabled start/end date.",
          setToastStatus,
          setToastDescription
        );
        return;
      }
    }
    setManualSelection(newValue);

    // If turning OFF => revert to a single range
    // (You can remove this if you don't want to force a single calendar.)
    if (!newValue) {
      setCalendars([
        {
          id: uuidv4(),
          dateRange: {
            startDate: moment().toDate(),
            endDate: moment().toDate(),
            key: "selection",
          },
          enableStartDate: true,
          enableEndDate: true,
        },
      ]);
      setTempDateValues([
        {
          startDate: moment().format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
        },
      ]);
      setCurrentCalendarIndex(0);
    }
  };

  const handleCampaignNeverEndsChange = (event) => {
    const newValue = event.target.checked;
    setCampaignNeverEnds(newValue);

    if (newValue) {
      setCalendars((prev) => {
        const next = [...prev];
        next.forEach((cal) => {
          cal.enableEndDate = false;
          cal.dateRange.endDate = null;
        });
        return next;
      });
    } else {
      setCalendars((prev) => {
        const next = [...prev];
        next.forEach((cal) => {
          cal.enableEndDate = true;
          cal.dateRange.endDate = moment().toDate();
        });
        return next;
      });
    }
  };

  const handlePublishNowChange = (event) => {
    const newValue = event.target.checked;
    setPublishNow(newValue);

    if (newValue) {
      setCalendars((prev) => {
        if (!prev.length) {
          // No calendars at all => create one
          return [
            {
              id: uuidv4(),
              enableStartDate: true,
              enableEndDate: true,
              dateRange: {
                startDate: moment().toDate(),
                endDate: moment().toDate(),
                key: "selection",
              },
            },
          ];
        }

        // Check if there's already at least one "today" range
        const hasToday = prev.some((cal) => {
          if (!cal.enableStartDate || !cal.dateRange.startDate) return false;
          return moment(cal.dateRange.startDate).isSame(moment(), "day");
        });

        // If we already have a "today" calendar, do nothing
        if (hasToday) {
          return prev;
        }

        // Otherwise, force the FIRST calendar to start "today"
        const next = [...prev];
        const first = { ...next[0] };
        first.enableStartDate = true;
        first.dateRange.startDate = moment().toDate();
        next[0] = first;
        return next;
      });

      // Also update temp date values for the first calendar if needed
      setTempDateValues((prevVals) => {
        if (!prevVals.length) {
          return [
            {
              startDate: moment().format("YYYY-MM-DD"),
              endDate: moment().format("YYYY-MM-DD"),
            },
          ];
        }
        const next = [...prevVals];
        next[0] = {
          ...next[0],
          startDate: moment().format("YYYY-MM-DD"),
        };
        return next;
      });
    } else {
      // If user unchecks publishNow, you can choose to do nothing
      // or maybe see if you want to set the "today" range to future, up to you.
    }
  };

  const handleAddNextCalendar = () => {
    const newCalendar = {
      id: uuidv4(),
      dateRange: {
        startDate: moment().toDate(),
        endDate: moment().toDate(),
        key: "selection",
      },
      enableStartDate: true,
      enableEndDate: true,
    };

    setCalendars((prev) => [...prev, newCalendar]);

    setTempDateValues((prev) => [
      ...prev,
      {
        startDate: moment().format("YYYY-MM-DD"),
        endDate: moment().format("YYYY-MM-DD"),
      },
    ]);
  };

  const handleNextCalendar = () => {
    if (currentCalendarIndex === calendars.length - 1) {
      handleAddNextCalendar();
      setTimeout(() => {
        setCurrentCalendarIndex((prevIndex) => prevIndex + 1);
      }, 50);
    } else {
      setCurrentCalendarIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePreviousCalendar = () => {
    if (currentCalendarIndex > 0) {
      setCurrentCalendarIndex((prevIndex) => prevIndex - 1);
    }
  };

  const confirmRemoveCalendar = (index) => {
    setCalendarToRemove(index);
    setAlertDeleteCalendar(true);
  };

  const executeRemoveCalendar = () => {
    if (calendarToRemove === null) return;

    setCalendars((prev) => prev.filter((_, i) => i !== calendarToRemove));
    setTempDateValues((prev) => prev.filter((_, i) => i !== calendarToRemove));

    const updatedCalendars = calendars.filter(
      (_, index) => index !== calendarToRemove
    );

    let newCurrentIndex = currentCalendarIndex;
    if (currentCalendarIndex > calendarToRemove) {
      newCurrentIndex = currentCalendarIndex - 1;
    } else if (currentCalendarIndex === calendarToRemove) {
      newCurrentIndex =
        calendarToRemove < updatedCalendars.length
          ? calendarToRemove
          : calendarToRemove - 1;
    }

    setCalendars(updatedCalendars);
    setCurrentCalendarIndex(newCurrentIndex);
    setAlertDeleteCalendar(false);
    setCalendarToRemove(null);
  };

  const handleSubmitVideo = (videoArray) => {
    setSelectedVideos((prev) => [...prev, ...videoArray]);
  };

  const handleSelectExistingVideo = (videoArray) => {
    setSelectedVideos((prev) => [...prev, ...videoArray]);
  };

  const handleRemoveVideo = (index) => {
    setSelectedVideos((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        initialValues={{
          name: name,
          client: client,
          observations: observations,
        }}
        validationSchema={validationSchema}
        onSubmit={handleFormikSubmit}
        enableReinitialize
      >
        {({ values, errors, touched, handleChange, handleBlur }) => (
          <Form style={styles.container}>
            <div style={styles.leftSection}>
              <TextField
                name="name"
                label="Campaign Name"
                variant="outlined"
                fullWidth
                sx={styles.select}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />

              <TextField
                name="client"
                label="Client Name"
                variant="outlined"
                fullWidth
                sx={styles.select}
                value={values.client}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.client && Boolean(errors.client)}
                helperText={touched.client && errors.client}
              />

              <TextField
                name="observations"
                label="Observations"
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                sx={styles.select}
                value={values.observations}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <div className="schedule-container">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "24px",
                    marginTop: "24px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                      position: "relative",
                      zIndex: 2,
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: "SeagoeUI",
                        color: "#ff4757",
                        textTransform: "uppercase",
                        letterSpacing: "1.5px",
                      }}
                    >
                      Timeline
                    </span>
                    <h2
                      style={{
                        fontSize: "2.5rem",
                        fontFamily: "SeagoeUIBold",
                        color: "#2c3e50",
                        margin: 0,
                        position: "relative",
                      }}
                    >
                      Schedule
                      <div
                        style={{
                          position: "absolute",
                          bottom: "-4px",
                          left: 0,
                          width: "40%",
                          height: "4px",
                          background: "#ff4757",
                          borderRadius: "2px",
                        }}
                      />
                    </h2>
                  </div>
                  <div
                    style={{
                      height: "2px",
                      flex: 1,
                      marginLeft: "24px",
                      background:
                        "linear-gradient(to right, rgba(255, 71, 87, 0.5) 0%, transparent 100%)",
                    }}
                  />
                </div>

                <div
                  style={{
                    background: "rgba(255,64,129,0.05)",
                    padding: "1.25vw 1.25vw 1.25vw 1.25vw",
                    borderRadius: "12px",
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "1vw",
                  }}
                >
                  {/* <FormControlLabel
                    control={
                      <PinkSwitch
                        checked={manualSelection}
                        onChange={handleManualSelectionChange}
                      />
                    }
                    label={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            color: "#2c3e50",
                          }}
                        >
                          Multiple Calendar Selection
                        </span>
                      </div>
                    }
                    sx={{
                      margin: 0,
                      alignItems: "center",
                      gap: 2,
                    }}
                  /> */}
                  <FormControlLabel
                    control={
                      <PinkSwitch
                        checked={publishNow}
                        onChange={handlePublishNowChange}
                      />
                    }
                    label={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                        }}
                      >
                        <span style={{ fontSize: "0.8vw", color: "#2c3e50" }}>
                          Publish campaign now
                        </span>
                      </div>
                    }
                    sx={{
                      margin: 0,
                      alignItems: "center",
                      gap: 2,
                    }}
                  />
                  <Divider
                    sx={{
                      height: "1px",
                      border: "none",
                      backgroundImage:
                        "linear-gradient(to right, rgba(255, 71, 87, 0.5) 0%, transparent 100%)",
                    }}
                  />
                  <FormControlLabel
                    control={
                      <PinkSwitch
                        checked={campaignNeverEnds}
                        onChange={handleCampaignNeverEndsChange}
                      />
                    }
                    label={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                        }}
                      >
                        <span style={{ fontSize: "0.8vw", color: "#2c3e50" }}>
                          Campaign never ends
                        </span>
                      </div>
                    }
                    sx={{
                      margin: 0,
                      alignItems: "center",
                      gap: 2,
                    }}
                  />
                </div>
              </div>

              <h3 className="calendar-title">DATE RANGE</h3>

              <div className="calendar-wrapper">
                <div className="calendar-transition-container">
                  {calendars.map((calendar, index) => (
                    <div
                      key={calendar.id}
                      className={`calendar-slide ${
                        index === currentCalendarIndex ? "active" : ""
                      }`}
                      style={{ zIndex: index === currentCalendarIndex ? 1 : 0 }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "0.75rem",
                          marginTop: "1rem",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "2rem",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.5rem",
                            }}
                          >
                            {/* <Checkbox
                              size="small"
                              color="black"
                              checked={calendar.enableStartDate}
                              onChange={(e) =>
                                handleToggleStartDate(index, e.target.checked)
                              }
                              disabled={publishNow && !calendar.enableStartDate}
                            /> */}
                            {/* <label style={{ fontSize: "0.8rem" }}>
                              Enable Start Date
                            </label> */}
                          </div>

                          {/* <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.5rem",
                            }}
                          >
                            <Checkbox
                              size="small"
                              color="black"
                              checked={calendar.enableEndDate}
                              onChange={(e) =>
                                handleToggleEndDate(index, e.target.checked)
                              }
                            />
                            <label style={{ fontSize: "0.8rem" }}>
                              Enable End Date
                            </label>
                          </div> */}
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "2rem",
                          }}
                        >
                          {calendar.enableStartDate && (
                            <TextField
                              style={{ display: publishNow ? "none" : "block" }}
                              type="date"
                              label="Start Date"
                              value={tempDateValues[index].startDate}
                              onChange={(e) =>
                                handleDateInputChange(
                                  index,
                                  "startDate",
                                  e.target.value
                                )
                              }
                              onBlur={(e) =>
                                handleDateInputBlur(
                                  index,
                                  "startDate",
                                  e.target.value
                                )
                              }
                              sx={styles.select}
                              inputProps={{
                                min: moment().format("YYYY-MM-DD"),
                              }}
                            />
                          )}

                          {calendar.enableEndDate && (
                            <TextField
                              type="date"
                              label="End Date"
                              value={tempDateValues[index].endDate}
                              onChange={(e) =>
                                handleDateInputChange(
                                  index,
                                  "endDate",
                                  e.target.value
                                )
                              }
                              onBlur={(e) =>
                                handleDateInputBlur(
                                  index,
                                  "endDate",
                                  e.target.value
                                )
                              }
                              sx={styles.select}
                              inputProps={{
                                min: moment().format("YYYY-MM-DD"),
                              }}
                            />
                          )}
                        </div>
                      </div>

                      {calendars.length > 1 && (
                        <Tooltip title="Delete current date range">
                          <button
                            type="button"
                            className="remove-calendar-btn"
                            onClick={() => confirmRemoveCalendar(index)}
                            style={{ marginTop: "1rem" }}
                          >
                            <MinusSquare size={20} />
                          </button>
                        </Tooltip>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              {manualSelection && (
                <div className="chevron-container">
                  <button
                    type="button"
                    className="chevron-btn"
                    onClick={handlePreviousCalendar}
                    disabled={currentCalendarIndex === 0}
                  >
                    <ChevronLeft size={24} />
                  </button>
                  <button
                    type="button"
                    className="chevron-btn"
                    onClick={handleNextCalendar}
                  >
                    <ChevronRight size={24} />
                  </button>
                </div>
              )}

              <div className="calendar-container-dates">
                {calendars.map((calendar, index) => (
                  <div
                    key={`${calendar.id}-${index}`}
                    className={`calendar-card ${
                      index === currentCalendarIndex ? "active-card" : ""
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => setCurrentCalendarIndex(index)}
                  >
                    {/* {publishNow && campaignNeverEnds ? (
                      <div />
                    ) : (
                      <div className="calendar-header">
                        <span className="calendar-number">{index + 1}</span>
                      </div>
                    )} */}
                    <div className="date-range">
                      <div className="start-date">
                        {calendar.enableStartDate ? (
                          publishNow ? (
                            <div style={{ fontStyle: "italic" }}>
                              Starting now
                            </div>
                          ) : (
                            <>
                              <div className="date">
                                {moment(calendar.dateRange.startDate).format(
                                  "DD"
                                )}
                              </div>
                              <div className="month-year">
                                <div>
                                  {moment(calendar.dateRange.startDate).format(
                                    "MMM"
                                  )}
                                </div>
                                <div>
                                  {moment(calendar.dateRange.startDate).format(
                                    "YYYY"
                                  )}
                                </div>
                              </div>
                            </>
                          )
                        ) : (
                          <div style={{ fontStyle: "italic" }}>
                            No Start Date
                          </div>
                        )}
                      </div>
                      <div className="range-divider">
                        <span className="arrow">→</span>
                      </div>
                      <div className="end-date">
                        {calendar.enableEndDate ? (
                          <>
                            <div className="date">
                              {moment(calendar.dateRange.endDate).format("DD")}
                            </div>
                            <div className="month-year">
                              <div>
                                {moment(calendar.dateRange.endDate).format(
                                  "MMM"
                                )}
                              </div>
                              <div>
                                {moment(calendar.dateRange.endDate).format(
                                  "YYYY"
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <div style={{ fontStyle: "italic" }}>No End Date</div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <button type="submit" className="add-video-button">
                Choose your devices →
              </button>
            </div>

            <div style={styles.rightSection}>
              <div
                style={{
                  marginBottom: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "24px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#ff4757",
                        fontFamily: "SeagoeUIBold",
                        textTransform: "uppercase",
                        letterSpacing: "2px",
                        opacity: 0.9,
                      }}
                    >
                      Media Library
                    </span>
                    <h2
                      style={{
                        fontSize: "2.5rem",
                        fontFamily: "SeagoeUIBold",
                        color: "#2c3e50",
                        margin: 0,
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                      }}
                    >
                      Upload Videos
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#94a3b8",
                          fontWeight: "500",
                          padding: "6px 12px",
                          background: "rgba(255,64,129,0.08)",
                          borderRadius: "20px",
                          letterSpacing: "0.5px",
                        }}
                      >
                        MP4 supported
                      </span>
                    </h2>
                  </div>

                  <div
                    style={{
                      height: "2px",
                      flex: 1,
                      background:
                        "linear-gradient(to right, rgba(255, 71, 87, 0.5) 0%, transparent 100%)",
                      marginLeft: "24px",
                    }}
                  />
                </div>

                <CustomButton
                  text={"Select Media Files"}
                  onClick={() => setIsModalOpen(true)}
                  style={{
                    marginTop: "22px",
                  }}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                {selectedVideos.length > 0 ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                        marginBottom: "20px",
                        padding: "0 12px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13px",
                          fontFamily: "SeagoeUIBold",
                          color: "#94a3b8",
                          textTransform: "uppercase",
                          letterSpacing: "1px",
                        }}
                      >
                        Selected Files
                      </span>
                      <div
                        style={{
                          height: "1px",
                          flex: 1,
                          background:
                            "linear-gradient(to right, #e2e8f0 0%, transparent 100%)",
                        }}
                      />
                      <span
                        style={{
                          padding: "4px 12px",
                          borderRadius: "20px",
                          fontSize: "13px",
                          color: "#64748b",
                          background: "rgba(226,232,240,0.5)",
                        }}
                      >
                        {selectedVideos.length} videos
                      </span>
                    </div>
                    {selectedVideos.map((video, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                          padding: "10px 20px",
                          background: "white",
                          borderRadius: "16px",
                          boxShadow: "0 4px 20px rgba(148,163,184,0.05)",
                          transition: "all 0.3s ease",
                          border: "1px solid rgba(226,232,240,0.8)",
                          position: "relative",
                          overflow: "hidden",
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.transform = "translateY(-2px)";
                          e.currentTarget.style.boxShadow =
                            "0 8px 30px rgba(148,163,184,0.12)";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.transform = "translateY(0)";
                          e.currentTarget.style.boxShadow =
                            "0 4px 20px rgba(148,163,184,0.05)";
                        }}
                      >
                        <div
                          style={{
                            width: "28px",
                            height: "28px",
                            borderRadius: "8px",
                            background: "rgba(255,64,129,0.08)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#ff4081",
                            fontSize: "14px",
                          }}
                        >
                          {index + 1}
                        </div>
                        <div
                          style={{
                            flex: 1,
                            background: "rgba(241,245,249,0.5)",
                            padding: "12px 16px",
                            borderRadius: "10px",
                            border: "1px solid rgba(226,232,240,0.6)",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#64748b"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                              <polyline points="14 2 14 8 20 8" />
                            </svg>
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#334155",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {video.title}.mp4
                            </span>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "12px",
                            padding: "8px 16px",
                            background: "rgba(241,245,249,0.5)",
                            borderRadius: "10px",
                            border: "1px solid rgba(226,232,240,0.6)",
                          }}
                        >
                          <label
                            style={{
                              fontSize: "14px",
                              color: "#64748b",
                              fontWeight: "500",
                            }}
                          >
                            Minimum displays
                          </label>
                          <TextField
                            type="number"
                            variant="outlined"
                            size="small"
                            value={videoFrequency[index] || 1}
                            onChange={(e) => {
                              const newValue = Math.max(
                                0,
                                parseInt(e.target.value) || 0
                              );
                              const updatedFrequency = [...videoFrequency];
                              updatedFrequency[index] = newValue;
                              setVideoFrequency(updatedFrequency);
                            }}
                            inputProps={{
                              min: 0,
                              style: {
                                textAlign: "center",
                                padding: "8px",
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#334155",
                              },
                            }}
                            sx={{
                              width: "90px",
                              "& .MuiOutlinedInput-root": {
                                background: "white",
                                borderRadius: "8px",
                                "& fieldset": {
                                  borderColor: "rgba(226,232,240,0.8)",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#ff4081",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#ff4081",
                                },
                              },
                            }}
                          />
                        </div>

                        <button
                          style={{
                            border: "none",
                            background: "none",
                            padding: "8px",
                            cursor: "pointer",
                            borderRadius: "8px",
                            transition: "all 0.2s ease",
                            color: "#64748b",
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.background =
                              "rgba(239,68,68,0.08)";
                            e.currentTarget.style.color = "#ef4444";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.background = "none";
                            e.currentTarget.style.color = "#64748b";
                          }}
                          onClick={() => handleRemoveVideo(index)}
                        >
                          <MinusSquare size={20} />
                        </button>
                      </div>
                    ))}
                  </>
                ) : (
                  <div
                    style={{
                      marginTop: "10px",
                      padding: "32px",
                      textAlign: "center",
                      color: "#64748b",
                      fontSize: "15px",
                      background: "rgba(241,245,249,0.5)",
                      borderRadius: "16px",
                      border: "1px dashed rgba(148,163,184,0.4)",
                    }}
                  >
                    No videos selected yet
                  </div>
                )}
              </div>

              <AddVideoDialog
                open={isModalOpen}
                onClose={handleModalClose}
                onSubmit={handleSubmitVideo}
                onSelect={handleSelectExistingVideo}
                instantUpload={false}
                isCampaign={true}
              />
            </div>
          </Form>
        )}
      </Formik>
      <AlertModal
        open={alertDeleteCalendar}
        handleClose={() => {
          setAlertDeleteCalendar(false);
          setCalendarToRemove(null);
        }}
        text="Are you sure you want to delete this calendar?"
        handleYes={executeRemoveCalendar}
      />
    </>
  );
}
