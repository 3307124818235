import fetcher from "../helpers/fetcher";

export async function GetAllCampaigns(page, searchTerm = "", dateFilter = "") {
  let path = `/campaigns?page=${page}&search=${searchTerm}&dateFilter=${dateFilter}`;
  let request = await fetcher(path, "GET");
  let content = await request.json();
  return content;
}

export async function CreateCampaign(body) {
  let path = `/campaigns`;
  let request = await fetcher(path, "POST", body);
  let content = await request.json();
  return content;
}

export async function DeleteCampaign(id, deleteVideos) {
  let path = `/campaigns/${id}?deleteVideos=${deleteVideos}`;
  let request = await fetcher(path, "DELETE");
  let content = await request.json();
  return content;
}

export async function GetCampaignInfo(id) {
  let path = `/campaigns/${id}/details`;
  let request = await fetcher(path, "GET");
  let content = await request.json();
  return content;
}

export async function UpdateCampaignDetails(id, body) {
  let path = `/campaigns/${id}`;
  let request = await fetcher(path, "PUT", body);
  let content = await request.json();
  return content;
}

export async function GetCampaignAnalytics(id, from, to, page) {
  let path = `/campaigns/${id}/analytics?from=${from}&to=${to}&page=${page}`;
  let request = await fetcher(path, "GET");
  let content = await request.json();
  return content;
}

export async function GenerateCampaignPlaylist(id) {
  let path = `/campaigns/${id}/generate-playlist`;
  let request = await fetcher(path, "POST");
  let content = await request.json();
  return content;
}
