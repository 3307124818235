import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  GetCampaignInfo,
  UpdateCampaignDetails,
} from "../../services/campaign";
import EditCampaignStep from "./tabs/edit-campaign";
import { keyframes, styled } from "@mui/material/styles";
import { Tabs, Tab, Box, CircularProgress } from "@mui/material";
import CampaignIcon from "@mui/icons-material/Campaign";
import LoopIcon from "@mui/icons-material/Loop";
import { Check } from "react-feather";
import EditLoopStep from "./tabs/edit-loop";
import PopSnackbar from "../../components/snackbar";
import { DeleteVideoGlobal, UploadVideo } from "../../services/video";
import AlertModal from "../../components/modal";

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const StyledTabs = styled(Tabs)(({ theme }) => ({
  position: "relative",
  width: "100%",
  background: "linear-gradient(135deg, #fafafa, #fff)",
  borderRadius: "8px 8px 0 0",
  boxShadow: "0 1px 1px rgba(0,0,0,0.1)",
  overflow: "hidden",
  "& .MuiTabs-indicator": {
    display: "none",
  },
}));

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontSize: "1rem",
    padding: theme.spacing(2, 1),
    fontFamily: "SeagoeUI, sans-serif",
    color: "rgba(0, 0, 0, 0.65)",
    opacity: 0.85,
    transition: "all 0.3s ease",
    position: "relative",
    width: "50%",
    borderRight: "1px solid rgba(0, 0, 0, 0.05)",
    "&:last-of-type": {
      borderRight: "none",
    },
    "& .MuiTab-iconWrapper": {
      marginRight: theme.spacing(1),
      fontSize: 22,
      transition: "transform 0.3s ease",
    },
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.03)",
      color: "#000",
      opacity: 1,
      "& .MuiTab-iconWrapper": {
        transform: "scale(1.1)",
      },
    },
    "&.Mui-selected": {
      color: "#000",
      opacity: 1,
      fontFamily: "SeagoeUIBold, sans-serif",
    },
  })
);

const StepIndicator = styled("span")(({ active, completed }) => ({
  width: "30px",
  height: "30px",
  borderRadius: "50%",
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: "12px",
  fontSize: "0.875rem",
  backgroundColor: completed ? "#000" : active ? "rgba(0, 0, 0, 0.1)" : "#fff",
  color: completed ? "#fff" : active ? "#000" : "rgba(0, 0, 0, 0.75)",
  border: `2px solid ${
    completed
      ? "#000"
      : active
      ? "rgba(97, 97, 97, 0.4)"
      : "rgba(97, 97, 97, 0.2)"
  }`,
  transition: "all 0.3s ease",
  transform: active ? "scale(1.1)" : "scale(1)",
}));

const TabPanel = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4, 2),
  animation: `${slideIn} 0.5s cubic-bezier(0.4, 0, 0.2, 1)`,
  "& > *": {
    animation: `${slideIn} 0.5s cubic-bezier(0.4, 0, 0.2, 1)`,
  },
}));

const ProgressArrow = styled("div")(({ activeTab }) => ({
  position: "absolute",
  left: activeTab === 0 ? "25%" : "75%",
  transform: "translateX(-50%)",
  width: 0,
  height: 0,
  borderLeft: "10px solid transparent",
  borderRight: "10px solid transparent",
  borderTop: "10px solid #ff4757",
  transition: "left 0.4s cubic-bezier(0.4, 0, 0.2, 1)",
  "&::before, &::after": {
    content: '""',
    position: "absolute",
    width: "4px",
    height: "4px",
    borderRadius: "50%",
    background: "#ff4757",
    animation: "particles 1.5s infinite",
    opacity: 0,
  },
  "&::before": {
    animationDelay: "0.2s",
    left: "-15px",
  },
  "&::after": {
    animationDelay: "0.4s",
    right: "-15px",
  },
  "@keyframes particles": {
    "0%": {
      transform: "translateY(0) scale(1)",
      opacity: 0,
    },
    "50%": {
      transform: "translateY(-20px) scale(0.5)",
      opacity: 0.8,
    },
    "100%": {
      transform: "translateY(-40px) scale(0)",
      opacity: 0,
    },
  },
}));

export default function EditCampaign() {
  const { id } = useParams();
  const campaignFormikRef = useRef(null);
  const loopStepRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [videos, setVideos] = useState([]);
  const [toastStatus, setToastStatus] = useState(false);
  const [toastDescription, setToastDescription] = useState("");
  const [calendars, setCalendars] = useState([{ startDate: "", endDate: "" }]);
  const [activeTab, setActiveTab] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [alertModalText, setAlertModalText] = useState("");

  const [campaignData, setCampaignData] = useState(null);

  const navigate = useNavigate();

  const [loopsData, setLoopsData] = useState({
    selectedDevices: [],
    filters: {
      partners: [],
      counties: [],
      cities: [],
      locations: [],
      tags: [],
      search: "",
    },
    timeInterval: 60,
  });

  const handleStatus = (description, setStatus, setDescription) => {
    setStatus(true);
    setDescription(description);
    setTimeout(() => {
      setStatus(false);
      setDescription("");
    }, 5000);
  };

  const handleTabChange = async (_, newTab) => {
    try {
      if (activeTab === 0 && newTab === 1) {
        if (campaignFormikRef.current) {
          await campaignFormikRef.current.submitForm();
        }

        const { name, client } = campaignFormikRef.current?.values || {};
        const { videos } = campaignFormikRef.current?.values || {};
        if (!name?.trim() || !client?.trim()) {
          handleStatus(
            "Campaign name and client name are required.",
            setToastStatus,
            setToastDescription
          );
          return;
        }
        if (!videos || videos.length === 0) {
          handleStatus(
            "Please select at least one video before proceeding.",
            setToastStatus,
            setToastDescription
          );
          return;
        }
      } else if (
        activeTab === 1 &&
        newTab === 0 &&
        loopStepRef.current?.saveLoopData
      ) {
        await loopStepRef.current.saveLoopData();
      }

      setActiveTab(newTab);
    } catch (error) {
      console.error("Error during tab switch:", error);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await GetCampaignInfo(id);
      if (response.status !== "OK") {
        throw new Error(response.error || "An error occurred");
      }
      const campaign = response.data;

      setCampaignData(campaign);

      const formatToYYYYMMDD = (dateStr) => {
        if (!dateStr) return "";
        const d = new Date(dateStr);
        return isNaN(d.getTime()) ? "" : d.toISOString().split("T")[0];
      };

      const newCalendars = campaign.schedules?.map((sch) => ({
        id: sch.ID,
        startDate: formatToYYYYMMDD(sch.start_date),
        endDate: formatToYYYYMMDD(sch.end_date),
      })) || [
        {
          startDate: new Date().toISOString().split("T")[0],
          endDate: new Date().toISOString().split("T")[0],
        },
      ];

      setCalendars(newCalendars);

      let newVideos = [];
      if (campaign.videos && campaign.videos.length > 0) {
        newVideos = campaign.videos.map((vc) => ({
          id: vc.video_id,
          frequency: vc.frequency,
          title: vc.Video?.title || "",
        }));
      }
      setVideos(newVideos);

      const campaignDevices =
        campaign.devices && campaign.devices.length > 0
          ? campaign.devices.map((dev) => ({
              device_id: dev.device_id,
              name: dev.name,
            }))
          : [];

      if (campaign.loop) {
        const loop = campaign.loop;

        const loopFilters = {
          partners: loop.partners ?? [],
          counties: loop.counties ?? [],
          cities: loop.cities ?? [],
          locations: loop.locations ?? [],
          tags: loop.tags ?? [],
          search: "",
        };

        const loopTimeInterval = loop.target_duration
          ? Math.round(loop.target_duration / 60)
          : 1;

        setLoopsData({
          selectedDevices: campaignDevices,
          filters: loopFilters,
          timeInterval: loopTimeInterval,
        });
      } else {
        setLoopsData({
          selectedDevices: [],
          filters: {
            partners: [],
            counties: [],
            cities: [],
            locations: [],
            tags: [],
            search: "",
          },
          timeInterval: 60,
        });
      }
    } catch (error) {
      handleStatus(error.message, setToastStatus, setToastDescription);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmitAll = async (updatedLoopsData) => {
    if (loadingSubmit) return;
    setLoadingSubmit(true);

    let newlyCreatedVideoIds = [];
    let shouldNavigate = true;

    try {
      const { videos = [], ...otherCampaignFields } = campaignData;

      const existingVideos = videos.filter(
        (v) => v.id && !String(v.id).startsWith("temp-") && !v.file
      );
      const newVideos = videos.filter(
        (v) => v.file || (v.id && String(v.id).startsWith("temp-"))
      );

      const finalVideos = existingVideos.map((vid) => ({
        id: vid.id,
        frequency: vid.frequency,
      }));

      const transformedDevices = updatedLoopsData.selectedDevices.map(
        (device) => ({
          device_id: device.device_id,
          name: device.name,
        })
      );

      // **Payload for Campaign Update**
      const finalPayload = {
        ...otherCampaignFields,
        devices: transformedDevices,
        loop: {
          ...updatedLoopsData,
          selectedDevices: undefined, // Remove `selectedDevices` from the loop
        },
        videos: finalVideos,
      };

      try {
        // Upload new videos and update final payload
        for (const vid of newVideos) {
          const formData = new FormData();
          formData.append("file", vid.file);
          formData.append("title", vid.title);
          formData.append("duration", vid.duration);
          formData.append("categories", JSON.stringify([16]));

          const createVideoResp = await UploadVideo(formData);
          if (createVideoResp.status !== "OK") {
            throw new Error(
              createVideoResp.error || "Failed to upload a new video."
            );
          }

          const newVideoId = createVideoResp.data.ID;
          newlyCreatedVideoIds.push(newVideoId);

          finalVideos.push({
            id: newVideoId,
            frequency: vid.frequency,
          });
        }

        // Update campaign with the full payload
        finalPayload.videos = finalVideos;

        const response = await UpdateCampaignDetails(
          id,
          JSON.stringify(finalPayload)
        );

        if (response.status !== "OK") {
          throw new Error(
            response.error || response.message || "Update failed"
          );
        }

        if (response.status === "OK" && response.regenerationTriggered) {
          setAlertModalText(
            "Campaign updated successfully. Playlist is regenerating for selected devices."
          );
          setOpenModal(true);
          shouldNavigate = false;

          setTimeout(() => {
            setOpenModal(false);
            navigate(`/devices?campaign_id=${id}`);
          }, 2500);

          return;
        }
      } catch (error) {
        handleStatus(error.message, setToastStatus, setToastDescription);
      } finally {
        setLoadingSubmit(false);
        if (shouldNavigate) {
          navigate(`/devices?campaign_id=${id}`);
        }
      }
    } catch (error) {
      console.error("Error before navigation:", error);
      setLoadingSubmit(false);
    }
  };

  const handleCampaignChange = useCallback((updatedData) => {
    setCampaignData((prev) => ({
      ...prev,
      ...updatedData,
    }));
  }, []);

  const handleLoopsChange = useCallback((updatedLoopsData) => {
    setLoopsData((prev) => ({
      ...prev,
      ...updatedLoopsData,
    }));
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {toastStatus && <PopSnackbar message={toastDescription} />}
      {loading ? (
        <CircularProgress />
      ) : (
        <div>
          <StyledTabs
            value={activeTab}
            onChange={handleTabChange}
            variant="fullWidth"
          >
            <StyledTab
              icon={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <StepIndicator
                    active={activeTab === 0}
                    completed={activeTab > 0}
                  >
                    {activeTab > 0 ? <Check fontSize="small" /> : "1"}
                  </StepIndicator>
                  <CampaignIcon />
                </div>
              }
              label="Campaign Setup"
              iconPosition="start"
            />
            <StyledTab
              icon={
                <div style={{ display: "flex", alignItems: "center" }}>
                  <StepIndicator
                    active={activeTab === 1}
                    completed={activeTab > 1}
                  >
                    2
                  </StepIndicator>
                  <LoopIcon />
                </div>
              }
              label="Choose devices"
              iconPosition="start"
            />
            <ProgressArrow activeTab={activeTab} />
          </StyledTabs>

          <TabPanel hidden={activeTab !== 0}>
            <EditCampaignStep
              setToastDescription={setToastDescription}
              setToastStatus={setToastStatus}
              handleStatus={handleStatus}
              goNext={() => setActiveTab(1)}
              onChange={handleCampaignChange}
              data={campaignData}
              videos={videos}
              formikRef={campaignFormikRef}
              initialCalendars={calendars}
            />
          </TabPanel>

          <TabPanel hidden={activeTab !== 1}>
            <EditLoopStep
              ref={loopStepRef}
              data={loopsData}
              onChange={handleLoopsChange}
              goBack={() => setActiveTab(0)}
              campaignData={campaignData}
              handleStatus={handleStatus}
              onSubmitAll={handleSubmitAll}
              loadingbtn={loadingSubmit}
            />
          </TabPanel>
        </div>
      )}
      <AlertModal
        open={openModal}
        text={alertModalText}
        handleClose={() => {
          setOpenModal(false);
          navigate(`/devices?campaign_id=${id}`);
        }}
        showYesButton={false}
        showNoButton={false}
        showOkButton={true}
        loading={false}
      />
    </>
  );
}
