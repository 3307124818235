import React, { useEffect, useState, useRef } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Autocomplete,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { DownloadVideo, GetAllVideos } from "../../services/video";

const styles = {
  select: {
    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
      backgroundColor: "#f9f9f9",
    },
    "& .MuiInputLabel-root": { color: "#555" },
    "&.Mui-disabled .MuiOutlinedInput-root": { backgroundColor: "#f0f0f0" },
  },
};

export default function AddVideoDialog({
  open,
  onClose,
  onSubmit,
  onSelect,
  instantUpload = true,
  categoryId = 0,
  isCampaign = false,
}) {
  // State for Add Video Tab
  const [addVideoFile, setAddVideoFile] = useState(null);
  const [addVideoPreview, setAddVideoPreview] = useState("");
  const [addVideoTitle, setAddVideoTitle] = useState("");
  const [addVideoDuration, setAddVideoDuration] = useState("");
  const [addIsLandscape, setAddIsLandscape] = useState(true);
  const [addIsTitleEditable, setAddIsTitleEditable] = useState(false);

  // State for Select Video Tab
  const [selectVideoId, setSelectVideoId] = useState([]); // Initialize as an array
  const [selectVideoPreview, setSelectVideoPreview] = useState("");
  const [selectVideoDuration, setSelectVideoDuration] = useState("");
  const [selectedVideoDetails, setSelectedVideoDetails] = useState([]); // Store details for selected videos
  const [selectVideoTitle, setSelectVideoTitle] = useState("");
  const [selectVideoIsLandscape, setSelectVideoIsLandscape] = useState(true);
  const [selectVideoFile, setSelectVideoFile] = useState(null);

  const [activeTab, setActiveTab] = useState(0);
  const [videos, setVideos] = useState([]);

  // Refs to store previous object URLs for cleanup
  const addVideoUrlRef = useRef(null);
  const selectVideoUrlRef = useRef(null);

  // Fetch videos when the dialog is opened
  useEffect(() => {
    if (open) {
      getVideos();
    }
  }, [open]);

  const getVideos = async () => {
    try {
      const content = await GetAllVideos();
      if (content.status !== "OK") {
        throw new Error(
          content.error || "Something went wrong, please try again later."
        );
      }

      const categoryIdList = [1, 4, 5, 8, 9, 10, 11, 12, 13, 14, 15, 16];

      var filteredVideos = content.data.filter((video) => video.ID !== 999999);

      if (isCampaign) {
        filteredVideos = filteredVideos.filter((video) =>
          video.categories.some((category) =>
            categoryIdList.includes(category.ID)
          )
        );
      }

      setVideos(filteredVideos);
    } catch (error) {
      console.error(error);
    }
  };

  // Handle file selection in Add Video Tab
  const handleAddFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      processAddVideo(file, true, "add");
    }
  };

  // Generic function to process video files
  const processAddVideo = (file, isAdd = false, tabType = "add") => {
    // Revoke previous object URL if exists
    if (tabType === "add" && addVideoUrlRef.current) {
      URL.revokeObjectURL(addVideoUrlRef.current);
    } else if (tabType === "select" && selectVideoUrlRef.current) {
      URL.revokeObjectURL(selectVideoUrlRef.current);
    }

    const videoUrl = URL.createObjectURL(file);
    if (tabType === "add") {
      addVideoUrlRef.current = videoUrl;
      setAddVideoFile(file);
      setAddVideoPreview(videoUrl);

      if (isAdd) {
        const fileName = file.name.split(".").slice(0, -1).join(".");
        setAddVideoTitle(fileName);
        setAddIsTitleEditable(true);
      }

      // Extract video duration
      const videoElement = document.createElement("video");
      videoElement.src = videoUrl;
      videoElement.addEventListener("loadedmetadata", () => {
        setAddVideoDuration(Math.floor(videoElement.duration));
      });
    }
  };

  // Handle video selection in Select Video Tab
  const handleSelectChange = async (e) => {
    const videoIds = e.target.value; // Array of selected IDs
    setSelectVideoId(videoIds);

    try {
      const newVideoDetails = [];

      for (const videoId of videoIds) {
        // Always fetch a fresh SAS URL for each video
        const video = videos.find((video) => video.ID === videoId);
        const { url } = await DownloadVideo(videoId); // Get fresh SAS URL

        const videoElement = document.createElement("video");
        videoElement.src = url;

        // Wait for metadata to load to get the video duration
        await new Promise((resolve) => {
          videoElement.addEventListener("loadedmetadata", () => {
            newVideoDetails.push({
              id: videoId,
              title: video.title,
              url, // Directly using SAS URL
              duration: Math.floor(videoElement.duration),
              exact_duration:
                video.exact_duration || Math.floor(videoElement.duration),
              categories: video.categories || [],
              isLandscape: video.is_landscape,
            });
            resolve();
          });
        });
      }

      // Update state with new video details
      setSelectedVideoDetails(newVideoDetails);
    } catch (error) {
      console.error("Error downloading videos:", error);
    }
  };

  // Clear states for both tabs
  const handleClear = () => {
    // Clear Add Video Tab
    setAddVideoFile(null);
    if (addVideoUrlRef.current) {
      URL.revokeObjectURL(addVideoUrlRef.current);
      addVideoUrlRef.current = null;
    }
    setAddVideoPreview("");
    setAddVideoTitle("");
    setAddVideoDuration("");
    setAddIsLandscape(true);
    setAddIsTitleEditable(false);

    // Clear Select Video Tab
    setSelectVideoId("");
    if (selectVideoUrlRef.current) {
      URL.revokeObjectURL(selectVideoUrlRef.current);
      selectVideoUrlRef.current = null;
    }
    setSelectVideoPreview("");
    setSelectVideoDuration("");
    setSelectVideoTitle("");
    setSelectVideoIsLandscape(true);
  };

  const handleClose = () => {
    handleClear();
    onClose();
  };

  const handleSubmit = () => {
    if (activeTab === 0) {
      // Add Video Tab Logic
      if (addVideoFile && addVideoTitle && addVideoDuration) {
        onSubmit([
          {
            title: addVideoTitle,
            file: addVideoFile,
            duration: parseInt(addVideoDuration, 10),
            is_landscape: addIsLandscape,
          },
        ]);
        handleClear();
        onClose();
      }
    } else {
      // Select Video Tab Logic
      if (selectVideoId.length > 0) {
        onSelect(
          selectedVideoDetails.map(({ id, title }) => ({
            id,
            categoryId,
            title,
          }))
        );
        handleClear();
        onClose();
      }
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    // Optionally, preserve or clear specific tab states here
  };

  // Cleanup object URLs when component unmounts
  useEffect(() => {
    return () => {
      if (addVideoUrlRef.current) {
        URL.revokeObjectURL(addVideoUrlRef.current);
      }
      if (selectVideoUrlRef.current) {
        URL.revokeObjectURL(selectVideoUrlRef.current);
      }
    };
  }, []);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <div
        className="tabs-container"
        style={{
          display: "inline-flex",
          borderRadius: "5px",
          padding: "1%",
          width: "98%",
          marginBottom: "10px",
          backgroundColor: "rgb(237,237,237,1)",
        }}
      >
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          TabIndicatorProps={{ style: { display: "none" } }}
          sx={{
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Tab
            label={
              <span style={{ fontSize: "13px", textTransform: "none" }}>
                Add Video
              </span>
            }
            sx={{
              color: activeTab === 0 ? "#000000" : "#6c757d",
              backgroundColor: activeTab === 0 ? "#FFFFFF" : "transparent",
              borderRadius: "5px",
              flex: 1,
            }}
          />
          <Tab
            label={
              <span style={{ fontSize: "13px", textTransform: "none" }}>
                Select Video
              </span>
            }
            sx={{
              color: activeTab === 1 ? "#000000" : "#6c757d",
              backgroundColor: activeTab === 1 ? "#FFFFFF" : "transparent",
              borderRadius: "5px",
              flex: 1,
            }}
          />
        </Tabs>
      </div>
      <DialogContent>
        {activeTab === 0 ? (
          <>
            <input
              type="file"
              accept="video/*"
              onChange={handleAddFileChange}
              style={{ marginBottom: 16 }}
            />
            <TextField
              sx={styles.select}
              label="Title"
              fullWidth
              value={addVideoTitle}
              onChange={(e) => setAddVideoTitle(e.target.value)}
              style={{ marginBottom: 5, marginTop: 5 }}
              disabled={!addIsTitleEditable}
            />
            {addVideoPreview && (
              <video
                key={`add-${addVideoPreview}`}
                width="100%"
                controls
                style={{
                  marginBottom: 16,
                  marginTop: 16,
                  borderRadius: 4,
                  boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
                }}
              >
                <source src={addVideoPreview} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
            <TextField
              sx={styles.select}
              label="Duration (seconds)"
              type="number"
              disabled
              fullWidth
              value={addVideoDuration}
              style={{ marginBottom: 5 }}
            />
            <FormControl component="fieldset" sx={{ marginBottom: 2 }}>
              <FormLabel component="legend">Is Video Landscape</FormLabel>
              <RadioGroup
                row
                value={addIsLandscape ? "yes" : "no"}
                onChange={(e) => setAddIsLandscape(e.target.value === "yes")}
              >
                <FormControlLabel
                  disabled={true}
                  value="yes"
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  disabled={true}
                  value="no"
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </>
        ) : (
          // Select Video Tab Content
          <>
            <Autocomplete
              multiple
              options={videos}
              getOptionLabel={(video) => video.title}
              sx={{ marginBottom: 2 }}
              onChange={(event, newValue) => {
                setSelectVideoId(newValue.map((video) => video.ID));
                handleSelectChange({
                  target: { value: newValue.map((video) => video.ID) },
                });
              }}
              value={
                videos.filter((video) => selectVideoId?.includes(video.ID)) ||
                []
              }
              isOptionEqualToValue={(option, value) => option.ID === value.ID}
              renderInput={(params) => (
                <TextField
                  sx={styles.select}
                  {...params}
                  label="Select Videos"
                  placeholder="Type to search"
                />
              )}
            />

            {selectedVideoDetails.map((video, index) => (
              <div key={video.id} style={{ marginBottom: "16px" }}>
                <h4 style={{ fontFamily: "SeagoeUIBold" }}>{video.title}</h4>
                <video
                  key={`select-${video.url}`}
                  width="100%"
                  controls
                  style={{
                    marginBottom: 8,
                    borderRadius: 4,
                    boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
                  }}
                >
                  <source src={video.url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                <p>Duration: {video.duration} seconds</p>
              </div>
            ))}

            {/* {selectVideoPreview && (
              <video
                key={`select-${selectVideoPreview}`} // Unique key to force re-mount
                width="100%"
                controls
                style={{
                  marginBottom: 16,
                  borderRadius: 4,
                  boxShadow: "0 2px 10px rgba(0,0,0,0.2)",
                }}
              >
                <source src={selectVideoPreview} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )} */}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <button onClick={handleClose} className="add-video-button">
          Cancel
        </button>
        <button
          className="add-video-button"
          onClick={handleSubmit}
          disabled={
            activeTab === 0
              ? !addVideoFile || !addVideoTitle || !addVideoDuration
              : selectVideoId.length === 0 ||
                selectedVideoDetails.length !== selectVideoId.length
          }
        >
          Add
        </button>
      </DialogActions>
    </Dialog>
  );
}
