import React from "react";

export default function CustomButton({ style, onClick, text }) {
  return (
    <button
      type="button"
      className="quantum-button"
      onClick={onClick}
      style={style}
    >
      <span className="quantum-orb"></span>
      <span className="quantum-text">{text}</span>
    </button>
  );
}
