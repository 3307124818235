import React, { useCallback, memo } from "react";
import { styled } from "@mui/material/styles";
import {
  Dialog,
  Box,
  DialogTitle,
  DialogActions,
  Button,
  Backdrop,
  Fade,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialog-paper": {
    background: "rgba(18, 18, 18, 0.95)",
    backdropFilter: "blur(10px)",
    borderRadius: "20px",
    border: "1px solid rgba(255, 255, 255, 0.1)",
    boxShadow: "0 8px 32px rgba(0, 0, 0, 0.4)",
    overflow: "hidden",
    transform: "translateZ(0)",
    backfaceVisibility: "hidden",
    [theme.breakpoints.down("sm")]: {
      margin: "16px",
      width: "calc(100% - 32px)",
    },
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "12px",
  padding: "12px 32px",
  textTransform: "none",
  fontFamily: "SeagoeUIBold",
  fontSize: "0.95rem",
  letterSpacing: "0.5px",
  transform: "translateZ(0)",
  "&.primary": {
    background: "#ffffff",
    color: "#000000",
    "&:hover": {
      background: "#f0f0f0",
    },
  },
  "&.secondary": {
    background: "transparent",
    border: "1px solid rgba(255, 255, 255, 0.2)",
    color: "#ffffff",
    "&:hover": {
      background: "rgba(255, 255, 255, 0.1)",
      border: "1px solid rgba(255, 255, 255, 0.4)",
    },
  },
  "&.disabled": {
    background: "rgba(255, 255, 255, 0.5)",
    color: "rgba(0, 0, 0, 0.5)",
    cursor: "not-allowed",
  },
}));

const buttonVariants = {
  hover: {
    y: -2,
    transition: {
      duration: 0.15,
      ease: [0.2, 0, 0.05, 1],
    },
  },
  tap: {
    y: 1,
    transition: {
      duration: 0.1,
    },
  },
};

const containerVariants = {
  hidden: {
    opacity: 0,
    scale: 0.98,
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.2,
      ease: "easeOut",
      staggerChildren: 0.1,
    },
  },
  exit: {
    opacity: 0,
    scale: 0.98,
    transition: {
      duration: 0.15,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 10 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
    },
  },
};

const AlertModal = memo(function AlertModal({
  text,
  open,
  handleClose,
  handleYes,
  showYesButton = true,
  showNoButton = true,
  showOkButton = false,
  hasCheckbox = false,
  checkboxText = "",
  handleCheckboxChange,
  childrenStyle = {},
  loading = false,
}) {
  const handleCheckboxChangeCallback = useCallback(
    (event) => {
      handleCheckboxChange?.(event);
    },
    [handleCheckboxChange]
  );

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 200,
          sx: {
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            backdropFilter: "blur(6px)",
          },
        },
      }}
      TransitionComponent={Fade}
      TransitionProps={{
        timeout: {
          enter: 200,
          exit: 150,
        },
      }}
    >
      <AnimatePresence mode="wait">
        {open && (
          <Box
            component={motion.div}
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <DialogTitle
              sx={{
                ...childrenStyle,
                textAlign: "center",
                fontSize: "1.25rem",
                fontFamily: "SeagoeUIBold",
                color: "#ffffff",
                padding: "32px 32px 24px",
              }}
            >
              <Box component={motion.div} variants={itemVariants}>
                {text}
              </Box>
            </DialogTitle>

            <DialogActions
              sx={{
                flexDirection: "column",
                gap: "24px",
                padding: "24px 32px",
                background: "rgba(18, 18, 18, 0.5)",
              }}
            >
              {hasCheckbox && (
                <Box component={motion.div} variants={itemVariants}>
                  <FormControlLabel
                    sx={{
                      margin: 0,
                      "& .MuiFormControlLabel-label": {
                        marginLeft: "12px",
                        color: "rgba(255, 255, 255, 0.8)",
                        fontFamily: "SeagoeUI",
                      },
                    }}
                    control={
                      <Checkbox
                        size="small"
                        onChange={handleCheckboxChangeCallback}
                        sx={{
                          color: "rgba(255, 255, 255, 0.5)",
                          "&.Mui-checked": {
                            color: "#ffffff",
                          },
                          "& .MuiSvgIcon-root": {
                            fontSize: 20,
                          },
                        }}
                      />
                    }
                    label={checkboxText}
                  />
                </Box>
              )}

              <Box
                component={motion.div}
                variants={itemVariants}
                sx={{
                  display: "flex",
                  gap: "16px",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                {showYesButton && (
                  <StyledButton
                    className="primary"
                    onClick={handleYes}
                    component={motion.button}
                    variants={buttonVariants}
                    whileHover="hover"
                    whileTap="tap"
                  >
                    Yes
                  </StyledButton>
                )}
                {showNoButton && (
                  <StyledButton
                    className="secondary"
                    onClick={handleClose}
                    component={motion.button}
                    variants={buttonVariants}
                    whileHover="hover"
                    whileTap="tap"
                  >
                    No
                  </StyledButton>
                )}
                {showOkButton && (
                  <StyledButton
                    className={loading ? "disabled" : "primary"}
                    onClick={handleClose}
                    autoFocus
                    component={motion.button}
                    variants={buttonVariants}
                    whileHover="hover"
                    whileTap="tap"
                    disabled={loading}
                  >
                    Ok
                  </StyledButton>
                )}
              </Box>
            </DialogActions>
          </Box>
        )}
      </AnimatePresence>
    </StyledDialog>
  );
});

export default AlertModal;
