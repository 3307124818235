import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { GetCampaignAnalytics } from "../../services/campaign";
import PopSnackbar from "../../components/snackbar";
import {
  Card as MuiCard,
  CardContent,
  Avatar,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Tabs,
  Tab,
  ToggleButtonGroup,
  ToggleButton,
  LinearProgress,
} from "@mui/material";
import { Eye, HardDrive, Award, Video } from "react-feather";
import CustomTable from "../../components/table";
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import BarChartIcon from "@mui/icons-material/BarChart";
import PieChartIcon from "@mui/icons-material/PieChart";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#AF19FF"];

const columns = [
  {
    field: "video_id",
    headerName: "Video ID",
    headerAlign: "center",
  },
  {
    field: "video_title",
    headerName: "Video Title",
    headerAlign: "center",
  },
  { field: "count", headerName: "Count", headerAlign: "center" },
];

const styles = {
  select: {
    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
      backgroundColor: "#f9f9f9",
    },
    "& .MuiInputLabel-root": { color: "#555" },
    "&.Mui-disabled .MuiOutlinedInput-root": { backgroundColor: "#f0f0f0" },
  },
};

const CustomTooltip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "#fff",
          padding: "10px 12px",
          border: "1px solid #e0e0e0",
          borderRadius: "4px",
          boxShadow: "0 2px 5px rgba(0,0,0,0.15)",
          fontSize: "14px",
          color: "#333",
          maxWidth: "200px",
          textAlign: "left",
        }}
      >
        <p
          style={{ margin: 0 }}
        >{`${payload[0].name}: ${payload[0].value} times`}</p>
      </div>
    );
  }

  return null;
};

const Card = ({ title, value, icon: Icon }) => {
  return (
    <MuiCard
      sx={{
        minWidth: 220,
        flex: "1 1 220px",
        marginTop: 1.5,
        marginBottom: 1.5,
        maxWidth: "100%",
        color: "#2D3748",
        background: "#fff",
        borderRadius: "16px",
        transition: "all 0.3s ease",
        boxShadow: "0 4px 12px rgba(0,0,0,0.05), 0 1px 3px rgba(0,0,0,0.03)",
        border: "1px solid rgba(0,0,0,0.06)",
        overflow: "hidden",
        position: "relative",
        "&:hover": {
          transform: "translateY(-6px)",
          boxShadow: "0 12px 20px rgba(0,0,0,0.1), 0 4px 8px rgba(0,0,0,0.06)",
          backgroundColor: "#FAFAFA",
        },
        "&::after": {
          content: '""',
          position: "absolute",
          bottom: 0,
          left: 0,
          width: "100%",
          height: "4px",
          background: "linear-gradient(90deg,rgb(0, 0, 0),rgb(37, 50, 59))",
          opacity: 0,
          transition: "opacity 0.3s ease",
        },
        "&:hover::after": {
          opacity: 1,
        },
      }}
    >
      <CardContent
        sx={{
          padding: "24px",
          "&:last-child": { paddingBottom: "24px" },
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div
            style={{
              fontSize: "0.875rem",
              fontFamily: "SeagoeUIBold",
              letterSpacing: "0.5px",
              textTransform: "uppercase",
              marginBottom: "8px",
              color: "rgba(0,0,0,0.6)",
            }}
          >
            {title}
          </div>
          <div
            style={{
              fontSize: "2rem",
              fontFamily: "SeagoeUIBold",
              lineHeight: 1.2,
              letterSpacing: "-0.5px",
              color: "#1A202C",
            }}
          >
            {value}
          </div>
        </div>

        {Icon && (
          <Avatar
            sx={{
              bgcolor: "white",
              border: "1px solid rgba(0,0,0,0.1)",
              width: 40,
              height: 40,
              transition: "all 0.3s ease",
              "&:hover": {
                transform: "rotate(10deg) scale(1.1)",
              },
            }}
          >
            <Icon style={{ color: "gray" }} />
          </Avatar>
        )}
      </CardContent>
    </MuiCard>
  );
};

function getPresetDates(option) {
  const today = new Date();
  const toDate = today.toISOString().slice(0, 10);
  const fromDate = new Date(today);

  switch (option) {
    case "lastDay":
      fromDate.setDate(today.getDate() - 1);
      break;
    case "last7Days":
      fromDate.setDate(today.getDate() - 6);
      break;
    case "last30Days":
      fromDate.setDate(today.getDate() - 29);
      break;
    default:
      return { from: toDate, to: toDate };
  }

  return {
    from: fromDate.toISOString().slice(0, 10),
    to: toDate,
  };
}

export default function CampaignAnalytics() {
  const { id: campaignID } = useParams();

  const [preset, setPreset] = useState("lastDay");
  const [{ from, to }, setDateRange] = useState(() => {
    return getPresetDates("lastDay");
  });

  const [page, setPage] = useState(0);
  const [reports, setReports] = useState([]);
  const [aggregatedReports, setAggregatedReports] = useState([]);
  const [campaign, setCampaign] = useState({});
  const [analytics, setAnalytics] = useState({
    total_videos: 0,
    top_video: "",
    top_video_count: 0,
    active_devices: 0,
    top_videos: [],
  });

  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);

  const [toastStatus, setToastStatus] = useState(false);
  const [toastDescription, setToastDescription] = useState("");

  const [tabIndex, setTabIndex] = useState(0);
  const [chartType, setChartType] = useState("bar");

  const handleStatus = useCallback((message) => {
    setToastStatus(true);
    setToastDescription(message);
    setTimeout(() => {
      setToastStatus(false);
      setToastDescription("");
    }, 5000);
  }, []);

  const fetchAnalytics = useCallback(async () => {
    if (!campaignID) return;
    try {
      setLoading(true);

      const data = await GetCampaignAnalytics(campaignID, from, to, page);

      if (data.status === "OK") {
        const d = data.data;
        setReports(d.reports ?? []);
        setAggregatedReports(d.aggregatedReports ?? []);
        setCampaign(d.campaign ?? {});
        setAnalytics(d.analytics ?? {});
        setTotal(d.totalCount ?? 0);
      } else {
        handleStatus(
          "Something went wrong, please try again later.",
          setToastStatus,
          setToastDescription
        );
      }
    } catch (err) {
      handleStatus(err.message, setToastStatus, setToastDescription);
    } finally {
      setLoading(false);
    }
  }, [campaignID, from, to, page, handleStatus]);

  useEffect(() => {
    fetchAnalytics();
  }, [campaignID, from, to, page, fetchAnalytics]);

  const noData = useMemo(() => {
    return (
      !loading &&
      reports.length === 0 &&
      (!analytics?.total_videos || analytics.total_videos === 0)
    );
  }, [loading, reports, analytics]);

  const handlePresetChange = (e) => {
    const selected = e.target.value;
    setPreset(selected);

    if (selected === "custom") return;
    const { from, to } = getPresetDates(selected);
    setDateRange({ from, to });
    setPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const allVideoPlays = useMemo(() => {
    if (!Array.isArray(aggregatedReports)) return [];
    return [...aggregatedReports].sort((a, b) => b.count - a.count);
  }, [aggregatedReports]);

  return (
    <div>
      {toastStatus && <PopSnackbar message={toastDescription} />}

      {loading ? (
        <LinearProgress
          color="inherit"
          style={{
            alignSelf: "center",
            width: 200,
            textAlign: "center",
          }}
        />
      ) : (
        <>
          <h1>{campaign.name}</h1>

          <FormControl sx={{ minWidth: 160 }}>
            <Select
              labelId="preset-label"
              value={preset}
              onChange={handlePresetChange}
              label="Preset"
              sx={styles?.select}
            >
              <MenuItem value="custom">Custom</MenuItem>
              <MenuItem value="lastDay">Last Day</MenuItem>
              <MenuItem value="last7Days">Last 7 Days</MenuItem>
              <MenuItem value="last30Days">Last 30 Days</MenuItem>
            </Select>
          </FormControl>

          {preset === "custom" && (
            <>
              <TextField
                sx={styles?.select}
                label="From"
                type="date"
                value={from}
                onChange={(e) =>
                  setDateRange((prev) => ({
                    ...prev,
                    from: e.target.value,
                  }))
                }
                style={{ marginLeft: 20 }}
                InputLabelProps={{ shrink: true }}
                inputProps={{ max: new Date().toISOString().split("T")[0] }}
                onKeyDown={(e) => {
                  if (e.key !== "Tab") e.preventDefault();
                }}
              />
              <TextField
                sx={styles?.select}
                label="To"
                type="date"
                value={to}
                onChange={(e) =>
                  setDateRange((prev) => ({
                    ...prev,
                    to: e.target.value,
                  }))
                }
                style={{ marginLeft: 20 }}
                InputLabelProps={{ shrink: true }}
                inputProps={{ max: new Date().toISOString().split("T")[0] }}
                onKeyDown={(e) => {
                  if (e.key !== "Tab") e.preventDefault();
                }}
              />
            </>
          )}
          {noData ? (
            <p>No data is here yet, select a different daterange</p>
          ) : (
            <>
              <Tabs
                value={tabIndex}
                onChange={(e, newIndex) => setTabIndex(newIndex)}
                sx={{
                  marginTop: 4,
                  "& .MuiTabs-indicator": {
                    backgroundColor: "#3f51b5",
                    height: 3,
                  },
                  "& .MuiTab-root": {
                    textTransform: "none",
                    fontWeight: 500,
                    fontSize: "0.9rem",
                    minWidth: 120,
                    transition: "all 0.2s",
                    "&.Mui-selected": {
                      color: "#3f51b5",
                      fontWeight: 700,
                    },
                    "&:hover": {
                      color: "#3f51b5",
                      opacity: 0.8,
                    },
                  },
                }}
              >
                <Tab label="Reports" />
                <Tab label="Statistics" />
              </Tabs>
              {tabIndex === 0 && (
                <CustomTable
                  columns={columns}
                  data={reports}
                  tableLoading={loading}
                  addNew={false}
                  search={false}
                  handleChangePage={handleChangePage}
                  page={page}
                  totalCount={total}
                />
              )}

              {tabIndex === 1 && (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      columnGap: "1.5rem",
                    }}
                  >
                    <Card
                      icon={Video}
                      title="Total Videos"
                      value={analytics.total_videos}
                    />
                    <Card
                      icon={Award}
                      title="Top Video"
                      value={analytics.top_video}
                    />
                    <Card
                      icon={Eye}
                      title="Top Video Plays"
                      value={analytics.top_video_count}
                    />
                    <Card
                      icon={HardDrive}
                      title="Active Devices"
                      value={analytics.active_devices}
                    />
                  </div>
                  <div
                    style={{
                      backgroundColor: "#f9f9f9",
                      border: "1px solid #e0e0e0",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                      padding: "1.5vw",
                      borderRadius: "0.75vw",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        rowGap: "0.1vw",
                      }}
                    >
                      <span
                        style={{ fontFamily: "SeagoeUIBold", fontSize: "1vw" }}
                      >
                        Performance Overview
                      </span>
                      <span
                        style={{
                          fontFamily: "SeagoeUI",
                          fontSize: "0.8vw",
                          color: "gray",
                          marginBottom: "0.25vw",
                        }}
                      >
                        Click on the icons to switch between different chart
                        types
                      </span>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        marginBottom: 20,
                      }}
                    >
                      <ToggleButtonGroup
                        value={chartType}
                        exclusive
                        onChange={(event, newType) => setChartType(newType)}
                        aria-label="chart type"
                      >
                        <ToggleButton value="bar" aria-label="bar chart">
                          <BarChartIcon />
                        </ToggleButton>
                        <ToggleButton value="pie" aria-label="pie chart">
                          <PieChartIcon />
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </div>

                    <div style={{ marginTop: 40 }}>
                      {chartType === "bar" ? (
                        <ResponsiveContainer
                          width="100%"
                          height={Math.max(300, allVideoPlays?.length * 50)}
                        >
                          <BarChart
                            data={allVideoPlays}
                            layout="vertical"
                            margin={{ left: 100 }}
                          >
                            <XAxis type="number" />
                            <YAxis dataKey="title" type="category" />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                            <Bar dataKey="count" fill="#8884d8" />
                          </BarChart>
                        </ResponsiveContainer>
                      ) : (
                        <ResponsiveContainer width="100%" height={400}>
                          <PieChart>
                            <Pie
                              data={analytics.top_videos}
                              dataKey="count"
                              nameKey="title"
                              cx="50%"
                              cy="50%"
                              outerRadius={100}
                              label
                              animationBegin={0}
                              animationDuration={1500}
                              animationEasing="ease-out"
                            >
                              {analytics.top_videos.map((entry, index) => (
                                <Cell
                                  key={`cell-${index}`}
                                  fill={COLORS[index % COLORS.length]}
                                  style={{
                                    filter:
                                      "drop-shadow(0px 0px 5px rgba(255, 255, 255, 0.5))",
                                    cursor: "pointer",
                                  }}
                                />
                              ))}
                            </Pie>

                            <Tooltip content={<CustomTooltip />} />
                            <Legend />
                          </PieChart>
                        </ResponsiveContainer>
                      )}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}
