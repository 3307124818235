import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Home,
  ChevronDown,
  ChevronUp,
  HardDrive,
  Settings,
  Video,
  Layers,
  User,
  Folder,
  PlayCircle,
  Airplay,
} from "react-feather";
import Avatar from "@mui/material/Avatar";

export function SidebarNav({ info, router: { location } }) {
  const navigate = useNavigate();

  const [showSettings, setShowSettings] = React.useState(false);

  const adminRole = info.role.includes("ROLE_ADMIN");

  let pathname = location.pathname;

  const handleDropdown = (e) => {
    const resetStates = () => {
      setShowSettings(false);
    };

    resetStates();

    switch (e) {
      case "settings":
        setShowSettings(true);
        break;
      default:
        break;
    }
  };

  const logOut = async () => {
    localStorage.removeItem("token");
    navigate("/login");
  };

  return (
    <div className="sidebar" id="sidebar">
      <div className="sidebar-inner">
        <div id="sidebar-menu" className="sidebar-menu">
          <div style={{ marginBottom: 20, color: "rgba(35, 43, 53)" }}>
            Overview
          </div>
          <ul>
            <li onClick={() => handleDropdown("dashboard")}>
              <Link to="/" style={{ textDecorationLine: "none" }}>
                <Home />{" "}
                <span
                  style={{
                    fontSize: "SeagoeUI",
                    marginLeft: 20,
                    color: "black",
                    cursor: "pointer",
                    fontFamily: pathname === "/" ? "SeagoeUIBold" : "SeagoeUI",
                  }}
                >
                  Dashboard
                </span>
              </Link>
            </li>
            {adminRole && (
              <li>
                <Link
                  to="/users"
                  style={{
                    textDecorationLine: "none",
                  }}
                >
                  <User />{" "}
                  <span
                    style={{
                      marginLeft: 20,
                      color: "black",
                      fontFamily: pathname.includes("/users")
                        ? "SeagoeUIBold"
                        : "SeagoeUI",
                    }}
                  >
                    Users
                  </span>
                </Link>
              </li>
            )}
            <li>
              <Link
                to="/devices"
                style={{
                  textDecorationLine: "none",
                }}
              >
                <HardDrive />{" "}
                <span
                  style={{
                    marginLeft: 20,
                    color: "black",
                    fontFamily: pathname.includes("/devices")
                      ? "SeagoeUIBold"
                      : "SeagoeUI",
                  }}
                >
                  Devices
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/videos"
                style={{
                  textDecorationLine: "none",
                }}
              >
                <Video />{" "}
                <span
                  style={{
                    marginLeft: 20,
                    color: "black",
                    fontFamily: pathname.includes("/videos")
                      ? "SeagoeUIBold"
                      : "SeagoeUI",
                  }}
                >
                  Videos
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/category"
                style={{
                  textDecorationLine: "none",
                }}
              >
                <Layers />{" "}
                <span
                  style={{
                    marginLeft: 20,
                    color: "black",
                    fontFamily:
                      pathname === "/category" ? "SeagoeUIBold" : "SeagoeUI",
                  }}
                >
                  Categories
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/partners"
                style={{
                  textDecorationLine: "none",
                }}
              >
                <Folder />{" "}
                <span
                  style={{
                    marginLeft: 20,
                    color: "black",
                    fontFamily:
                      pathname.includes("/partner") ||
                      pathname.includes("/locations")
                        ? "SeagoeUIBold"
                        : "SeagoeUI",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Partners
                </span>
              </Link>
            </li>
            <li>
              <Link
                to="/campaigns"
                style={{
                  textDecorationLine: "none",
                }}
              >
                <Airplay
                  style={{
                    color: pathname.includes("/campaigns")
                      ? "black"
                      : "inherit",
                  }}
                />
                <span
                  style={{
                    marginLeft: 20,
                    color: "black",
                    fontFamily: pathname.includes("/campaigns")
                      ? "SeagoeUIBold"
                      : "SeagoeUI",
                  }}
                >
                  Campaigns
                </span>
              </Link>
            </li>
            {/* <li>
              <Link
                to="/loops"
                style={{
                  textDecorationLine: "none",
                }}
              >
                <PlayCircle
                  style={{
                    color: pathname.includes("/loops") ? "black" : "inherit",
                  }}
                />
                <span
                  style={{
                    marginLeft: 20,
                    color: "black",
                    fontFamily: pathname.includes("/loops")
                      ? "SeagoeUIBold"
                      : "SeagoeUI",
                  }}
                >
                  Playlist
                </span>
              </Link>
            </li> */}
            {adminRole && (
              <li>
                <a onClick={() => setShowSettings(!showSettings)}>
                  <Settings />{" "}
                  <span style={{ fontSize: "SeagoeUI", marginLeft: 20 }}>
                    Settings
                  </span>
                  {showSettings ? (
                    <ChevronUp className="chevron" />
                  ) : (
                    <ChevronDown className="chevron" />
                  )}
                </a>

                <ul
                  className={
                    showSettings ? "sidebar-dropdown show" : "sidebar-dropdown"
                  }
                >
                  <li>
                    <Link
                      to="/logs"
                      style={{
                        textDecorationLine: "none",
                        color: "black",
                        paddingLeft: 60,
                        fontFamily: pathname.includes("/logs")
                          ? "SeagoeUIBold"
                          : "SeagoeUI",
                      }}
                      onClick={() => handleDropdown("settings")}
                    >
                      View Logs
                    </Link>
                  </li>
                </ul>
              </li>
            )}
          </ul>
        </div>
        <div className="overview-sidebar">
          <div className="avatar-sidebar">
            <div className="avatar-top-right">
              <p className="role-sidebar">Healthcare TV</p>
            </div>
            <Avatar
              className="avatar-popover"
              alt={info.name}
              sx={{
                width: 45,
                height: 45,
                border: (theme) => `solid 2px white`,
              }}
            >
              {info.name.charAt(0).toUpperCase()}
            </Avatar>
          </div>
          <p className="name-sidebar">{info.name}</p>
          <p className="email-sidebar">{info.email}</p>
          <div className="button-sidebar" onClick={logOut}>
            <p
              style={{
                color: "#fff",
                fontFamily: "SeagoeUIBold",
                fontSize: "0.8rem",
              }}
            >
              Log out
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

export default withRouter(SidebarNav);
