import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  LinearProgress,
  TableHead,
  TableRow,
  Collapse,
} from "@mui/material";
import SearchCustomTable from "./search";
import AddIcon from "@mui/icons-material/Add";

const CustomTable = ({
  columns,
  data,
  page,
  tableLoading,
  search,
  inputText,
  setInputText,
  totalCount,
  handleChangePage,
  addNew,
  addNewFunction,
  addNewTitle,
  secondAddNew,
  secondAddNewFunction,
  secondAddNewTitle,
  expandedRows,
  onToggleRow,
  allowToggleRow = false,
  rowsPerPage = 10,
}) => {
  const isRowExpanded = (id) => expandedRows?.includes(id);

  return (
    <div className="custom-table-container">
      <div className="search-and-add">
        <div style={{ width: "35%" }}>
          {search && (
            <SearchCustomTable
              inputText={inputText}
              setInputText={setInputText}
            />
          )}
        </div>
        <div style={{ display: "flex", columnGap: 10 }}>
          {secondAddNew && (
            <div className="new-asset-btn" onClick={secondAddNewFunction}>
              <AddIcon style={{ fontSize: "1rem", marginTop: "1px" }} />{" "}
              <div className="new-asset-btn-text">{secondAddNewTitle}</div>
            </div>
          )}
          {addNew && (
            <div className="new-asset-btn" onClick={addNewFunction}>
              <AddIcon style={{ fontSize: "1rem", marginTop: "1px" }} />{" "}
              <div className="new-asset-btn-text">{addNewTitle}</div>
            </div>
          )}
        </div>
      </div>
      <TableContainer sx={{ maxWidth: "100%", overflowX: "auto" }}>
        <Table sx={{ tableLayout: "fixed" }}>
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  key={column.field}
                  align="center"
                  style={{
                    width: column.width,
                    fontSize: 15,
                    color: "rgba(35, 43, 53, 0.8)",
                    fontFamily: "SeagoeUIBold",
                  }}
                >
                  {column.headerName}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableLoading ? (
              <TableRow>
                <TableCell colSpan={columns.length} align="center">
                  <div style={{ margin: 25, marginBlock: 30 }}>
                    <LinearProgress
                      color="inherit"
                      style={{
                        alignSelf: "center",
                        width: 200,
                        textAlign: "center",
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              data?.map((row, rowIndex) => (
                <React.Fragment key={`fragment-${row.id || rowIndex}`}>
                  <TableRow
                    key={`row-${row.id || rowIndex}`}
                    onClick={() =>
                      allowToggleRow && onToggleRow(row.id || rowIndex)
                    }
                    sx={{
                      cursor:
                        row.sublocations?.length > 0 ? "pointer" : "default",
                    }}
                  >
                    {columns?.map((column, colIndex) => (
                      <TableCell
                        sx={{ fontFamily: "SeagoeUI" }}
                        key={`${row.id || rowIndex}-${
                          column.field || colIndex
                        }`}
                        align="center"
                      >
                        {column.renderCell
                          ? column.renderCell({ row })
                          : row[column.field]}
                      </TableCell>
                    ))}
                  </TableRow>

                  <TableRow key={`collapse-${row.id || rowIndex}`}>
                    <TableCell
                      colSpan={columns.length}
                      style={{ padding: 0, borderBottom: 0 }}
                    >
                      <Collapse
                        in={isRowExpanded(row.id || rowIndex)}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Table
                          size="small"
                          sx={{
                            tableLayout: "fixed",
                            backgroundColor: "#ffffff",
                            borderRadius: "8px",
                            width: "100%",
                          }}
                        >
                          <TableBody>
                            {row.sublocations?.map((sublocation, subIndex) => (
                              <TableRow
                                key={`${row.id || rowIndex}-${
                                  sublocation.id || subIndex
                                }`}
                                sx={{
                                  "&:hover": {
                                    backgroundColor: "#f4f4f4",
                                  },
                                  transition: "background-color 0.3s ease",
                                }}
                              >
                                {columns?.map((column, colIndex) => (
                                  <TableCell
                                    key={`${sublocation.id || subIndex}-${
                                      column.field || colIndex
                                    }`}
                                    align="center"
                                    sx={{
                                      fontSize: "14px",
                                      color: "#555",
                                      fontFamily: "SeagoeUI",
                                      padding: "10px 16px",
                                      borderBottom: "1px solid #e0e0e0",
                                    }}
                                  >
                                    {column.renderCell
                                      ? column.renderCell({ row: sublocation })
                                      : sublocation[column.field]}
                                  </TableCell>
                                ))}
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          sx={{ display: "inline-flex" }}
          component="div"
          count={totalCount}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[]}
          showFirstButton={true}
          showLastButton={true}
        />
      </TableContainer>
    </div>
  );
};

export default CustomTable;
