import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as yup from "yup";
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Tooltip,
  Divider,
} from "@mui/material";
import CustomButton from "../../../components/button";
import { ChevronLeft, ChevronRight, MinusSquare } from "react-feather";
import moment from "moment";
import AlertModal from "../../../components/modal";
import AddVideoDialog from "../../../components/modal/videodialog";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { v4 as uuidv4 } from "uuid";
const validationSchema = yup.object().shape({
  name: yup.string().required("Campaign name is required"),
  client: yup.string().required("Client name is required"),
});

const PinkSwitch = styled((props) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: "#ff4757",
        opacity: 1,
        border: 0,
        ...theme.applyStyles("dark", {
          backgroundColor: "#ff4757",
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#ff4757",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.grey[100],
      ...theme.applyStyles("dark", {
        color: theme.palette.grey[600],
      }),
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: 0.7,
      ...theme.applyStyles("dark", {
        opacity: 0.3,
      }),
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: "#E9E9EA",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
    ...theme.applyStyles("dark", {
      backgroundColor: "#39393D",
    }),
  },
}));

const styles = {
  select: {
    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
    "& .MuiOutlinedInput-root": {
      borderRadius: "12px",
      backgroundColor: "#f9f9f9",
    },
    "& .MuiInputLabel-root": { color: "#555" },
    "&.Mui-disabled .MuiOutlinedInput-root": { backgroundColor: "#f0f0f0" },
  },
  container: {
    display: "flex",
    gap: "40px",
    width: "100%",
    height: "100%",
  },
  leftSection: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  rightSection: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    alignItems: "center",
  },
  videoList: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
  },
  videoItem: {
    padding: "10px",
    backgroundColor: "#f9f9f9",
    border: "1px solid #ccc",
    borderRadius: "4px",
    fontSize: "1rem",
    fontFamily: "SeagoeUI",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  videoTitle: {
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
};

export default function EditCampaignStep({
  onChange = () => {},
  goNext = () => {},
  formikRef,
  data,
  videos,
  initialCalendars = [],
  handleStatus,
  setToastStatus,
  setToastDescription,
}) {
  const [currentCalendarIndex, setCurrentCalendarIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [calendars, setCalendars] = useState([]);
  const [tempDateValues, setTempDateValues] = useState([]);
  const [manualSelection, setManualSelection] = useState(false);
  const [calendarToRemove, setCalendarToRemove] = useState(null);
  const [publishNow, setPublishNow] = useState(false);
  const [campaignNeverEnds, setCampaignNeverEnds] = useState(false);
  const [alertDeleteCalendar, setAlertDeleteCalendar] = useState(false);

  const handleCampaignNeverEndsChange = (checked) => {
    setCampaignNeverEnds(checked);

    if (checked) {
      // Disable end date for all calendars
      setCalendars((prev) => {
        return prev.map((cal) => ({
          ...cal,
          enableEndDate: false,
          dateRange: {
            ...cal.dateRange,
            endDate: null,
          },
        }));
      });

      // Optionally also update tempDateValues (if used in edit)
      setTempDateValues((prev) => {
        return prev.map((val) => ({
          ...val,
          endDate: null,
        }));
      });
    } else {
      // Re-enable end date for all calendars
      setCalendars((prev) => {
        return prev.map((cal) => ({
          ...cal,
          enableEndDate: true,
          dateRange: {
            ...cal.dateRange,
            endDate: moment().toDate(),
          },
        }));
      });

      // Restore temp endDate to today (or keep previous if you prefer)
      setTempDateValues((prev) => {
        return prev.map((val) => ({
          ...val,
          endDate: moment().format("YYYY-MM-DD"),
        }));
      });
    }
  };

  const handleToggleStartDate = (index, checked) => {
    setCalendars((prev) => {
      const next = [...prev];
      const cal = { ...next[index] };

      if (!checked) {
        // User unchecks "Enable Start Date"
        // Do NOT remove the stored date; just set the enableStartDate flag to false.
        cal.enableStartDate = false;
      } else {
        // User checks "Enable Start Date"
        cal.enableStartDate = true;
        // Fallback to whatever was in temp state, or "today"
        const fallback = tempDateValues[index].startDate
          ? moment(tempDateValues[index].startDate, "YYYY-MM-DD", true)
          : moment();
        cal.dateRange.startDate = fallback.isValid()
          ? fallback.toDate()
          : moment().toDate();
      }

      next[index] = cal;

      // Now apply the "publishNow" rules
      const nextTempVals = [...tempDateValues];
      const result = applyCampaignNowRules(index, next, nextTempVals);
      if (result) {
        // Sync back any updated temp values from applyCampaignNowRules
        setTempDateValues(result.nextTempVals);
        return result.nextCalendars;
      }

      return next;
    });

    // Also update tempDateValues
    setTempDateValues((prev) => {
      const next = [...prev];

      if (!checked) {
        // If the user DISABLES the start date,
        // you can leave `startDate` as-is so you do NOT lose it.
        // next[index].startDate = ""  <-- Remove this if you want to preserve the date in state
      } else {
        // If the user ENABLES it, ensure it’s set to something valid
        next[index] = {
          ...next[index],
          startDate: next[index].startDate || moment().format("YYYY-MM-DD"),
        };
      }

      return next;
    });
  };

  const handleToggleEndDate = (index, checked) => {
    setCalendars((prev) => {
      const next = [...prev];
      const cal = { ...next[index] };

      if (!checked) {
        cal.enableEndDate = false;
        cal.dateRange.endDate = null;
      } else {
        cal.enableEndDate = true;
        const fallback = tempDateValues[index].endDate
          ? moment(tempDateValues[index].endDate, "YYYY-MM-DD", true)
          : moment();
        cal.dateRange.endDate = fallback.isValid()
          ? fallback.toDate()
          : moment().toDate();
      }

      next[index] = cal;

      // Apply the "publishNow" rules again
      const nextTempVals = [...tempDateValues];
      const result = applyCampaignNowRules(index, next, nextTempVals);
      if (result) {
        setTempDateValues(result.nextTempVals);
        return result.nextCalendars;
      }

      return next;
    });

    // Reflect changes in tempDateValues
    setTempDateValues((prev) => {
      const next = [...prev];
      if (!checked) {
        next[index] = { ...next[index], endDate: "" };
      } else {
        next[index] = {
          ...next[index],
          endDate: next[index].endDate || moment().format("YYYY-MM-DD"),
        };
      }
      return next;
    });
  };

  const handleDateInputChange = (index, field, value) => {
    setTempDateValues((prev) =>
      prev.map((item, i) => (i === index ? { ...item, [field]: value } : item))
    );
  };

  const handleFormikSubmit = (values) => {
    // Make sure we have at least one video
    if (!values.videos || values.videos.length === 0) {
      handleStatus(
        "Please select at least one video before proceeding.",
        setToastStatus,
        setToastDescription
      );
      return; // don’t goNext
    }

    // Convert calendars state into the final schedules
    const schedules = calendars.map((cal) => ({
      id: cal.id || null,
      start_date:
        cal.enableStartDate && cal.dateRange.startDate
          ? moment(cal.dateRange.startDate).format("YYYY-MM-DD")
          : null,
      end_date:
        cal.enableEndDate && cal.dateRange.endDate
          ? moment(cal.dateRange.endDate).format("YYYY-MM-DD")
          : null,
    }));

    // Pass everything up
    onChange({
      name: values.name,
      client: values.client,
      observations: values.observations,
      videos: values.videos,
      schedules,
    });

    // Then proceed
    goNext();
  };

  const handleDateInputBlur = (index, field, value) => {
    const cal = calendars[index];
    if (!cal) return;

    // If date is disabled => revert
    if (
      (field === "startDate" && !cal.enableStartDate) ||
      (field === "endDate" && !cal.enableEndDate)
    ) {
      revertTempDate(index, field);
      return;
    }

    const newDate = moment(value, "YYYY-MM-DD", true);
    if (!newDate.isValid()) {
      handleStatus(
        "Please enter a valid date.",
        setToastStatus,
        setToastDescription
      );
      revertTempDate(index, field);
      return;
    }

    // No date in the past
    const today = moment().startOf("day");
    if (newDate.isBefore(today, "day")) {
      handleStatus(
        "You cannot select a date before today.",
        setToastStatus,
        setToastDescription
      );
      revertTempDate(index, field);
      return;
    }

    // If startDate, check it isn't after endDate
    if (field === "startDate" && cal.enableEndDate && cal.dateRange.endDate) {
      if (newDate.isAfter(moment(cal.dateRange.endDate), "day")) {
        handleStatus(
          "Start date cannot be after end date.",
          setToastStatus,
          setToastDescription
        );
        revertTempDate(index, field);
        return;
      }
    }
    // If endDate, check it isn't before startDate
    if (field === "endDate" && cal.enableStartDate && cal.dateRange.startDate) {
      if (newDate.isBefore(moment(cal.dateRange.startDate), "day")) {
        handleStatus(
          "End date cannot be before start date.",
          setToastStatus,
          setToastDescription
        );
        revertTempDate(index, field);
        return;
      }
    }

    // Update the actual calendars state
    setCalendars((prev) => {
      const next = [...prev];
      const updatedCal = { ...next[index] };

      if (field === "startDate") {
        updatedCal.dateRange.startDate = newDate.toDate();
      } else {
        updatedCal.dateRange.endDate = newDate.toDate();
      }
      next[index] = updatedCal;

      // Now apply "publishNow" rules
      const nextTempVals = [...tempDateValues];
      const result = applyCampaignNowRules(index, next, nextTempVals);
      if (result) {
        setTempDateValues(result.nextTempVals);
        return result.nextCalendars;
      }
      return next;
    });
  };

  const revertTempDate = (index, field) => {
    setTempDateValues((prev) => {
      const next = [...prev];
      const cal = calendars[index];
      if (!cal) return prev;

      // If the date is disabled => just put empty string
      if (
        (field === "startDate" && !cal.enableStartDate) ||
        (field === "endDate" && !cal.enableEndDate)
      ) {
        next[index] = { ...next[index], [field]: "" };
      } else {
        // revert to the actual date in state
        const dateObj =
          field === "startDate"
            ? cal.dateRange.startDate
            : cal.dateRange.endDate;
        next[index] = {
          ...next[index],
          [field]: dateObj ? moment(dateObj).format("YYYY-MM-DD") : "",
        };
      }
      return next;
    });
  };

  const handleManualSelectionChange = (checked) => {
    // If turning it on, check that all calendars have both start/end date enabled
    // (Only if you want to match AddCampaignStep's logic exactly)
    if (checked) {
      const allCalendarsHaveBoth = calendars.every(
        (cal) => cal.enableStartDate && cal.enableEndDate
      );
      if (!allCalendarsHaveBoth) {
        handleStatus(
          "Cannot enable multiple date ranges because at least one calendar has a disabled start or end date.",
          setToastStatus,
          setToastDescription
        );
        return;
      }
    }

    setManualSelection(checked);

    // If turning it off => revert to a single range
    if (!checked) {
      // Keep the first calendar only
      const firstCal = calendars[0] || {
        id: uuidv4(),
        enableStartDate: true,
        enableEndDate: true,
        dateRange: {
          startDate: moment().toDate(),
          endDate: moment().toDate(),
          key: "selection",
        },
      };

      setCalendars([firstCal]);
      setTempDateValues([
        {
          startDate: firstCal.dateRange.startDate
            ? moment(firstCal.dateRange.startDate).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
          endDate: firstCal.dateRange.endDate
            ? moment(firstCal.dateRange.endDate).format("YYYY-MM-DD")
            : moment().format("YYYY-MM-DD"),
        },
      ]);
      setCurrentCalendarIndex(0);
    }
  };

  const handlePublishNowChange = (checked) => {
    setPublishNow(checked);

    if (checked) {
      // 1) Check if any existing calendar starts "today"
      const hasTodayCalendar = calendars.some((cal) => {
        if (!cal.enableStartDate || !cal.dateRange.startDate) return false;
        return moment(cal.dateRange.startDate).isSame(moment(), "day");
      });

      // 2) If none do, either:
      //    (a) pick the first calendar and set its start to "today", or
      //    (b) add a new calendar that starts today – your choice

      if (!hasTodayCalendar) {
        setCalendars((prev) => {
          if (!prev.length) {
            // No calendars? Create one
            return [
              {
                id: uuidv4(),
                enableStartDate: true,
                enableEndDate: true,
                dateRange: {
                  startDate: moment().toDate(),
                  endDate: moment().toDate(),
                  key: "selection",
                },
              },
            ];
          }
          // We do have at least one calendar, but none start today –
          // let's force the *first* one to start "today"
          const next = [...prev];
          next[0] = {
            ...next[0],
            enableStartDate: true,
            dateRange: {
              ...next[0].dateRange,
              startDate: moment().toDate(),
            },
          };
          return next;
        });

        setTempDateValues((prev) => {
          if (!prev.length) {
            return [
              {
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment().format("YYYY-MM-DD"),
              },
            ];
          }
          const updated = [...prev];
          updated[0] = {
            ...updated[0],
            startDate: moment().format("YYYY-MM-DD"),
          };
          return updated;
        });
      }
    }
  };

  useEffect(() => {
    if (initialCalendars && initialCalendars.length > 0) {
      const mapped = initialCalendars.map((cal) => {
        return {
          id: cal.id || uuidv4(),
          enableStartDate: !!cal.startDate,
          enableEndDate: !!cal.endDate,
          dateRange: {
            startDate: cal.startDate
              ? moment(cal.startDate, "YYYY-MM-DD", true).toDate()
              : null,
            endDate: cal.endDate
              ? moment(cal.endDate, "YYYY-MM-DD", true).toDate()
              : null,
            key: "selection",
          },
        };
      });

      setCalendars(mapped);

      const temp = mapped.map((c) => ({
        startDate: c.dateRange.startDate
          ? moment(c.dateRange.startDate).format("YYYY-MM-DD")
          : "",
        endDate: c.dateRange.endDate
          ? moment(c.dateRange.endDate).format("YYYY-MM-DD")
          : "",
      }));
      setTempDateValues(temp);

      const allEndDatesNull = mapped.every((c) => c.dateRange.endDate === null);
      setCampaignNeverEnds(allEndDatesNull); // This sets the toggle state
      if (allEndDatesNull) {
        // Make sure calendars reflect disabled endDate
        setCalendars((prev) =>
          prev.map((cal) => ({
            ...cal,
            enableEndDate: false,
            dateRange: {
              ...cal.dateRange,
              endDate: null,
            },
          }))
        );
      }

      // Handle manual/publishNow logic
      if (mapped.length > 1) setManualSelection(true);

      const firstStart = mapped[0].dateRange.startDate;
      if (firstStart && moment(firstStart).isSame(moment(), "day")) {
        setPublishNow(true);
      }
    } else {
      const defaultCal = {
        id: uuidv4(),
        enableStartDate: true,
        enableEndDate: true,
        dateRange: {
          startDate: moment().toDate(),
          endDate: moment().toDate(),
          key: "selection",
        },
      };
      setCalendars([defaultCal]);
      setTempDateValues([
        {
          startDate: moment().format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
        },
      ]);
    }
  }, [initialCalendars]);

  const handleNextCalendar = () => {
    if (currentCalendarIndex === calendars.length - 1) {
      // Add a new blank range
      const newCal = {
        id: uuidv4(),
        enableStartDate: true,
        enableEndDate: true,
        dateRange: {
          startDate: moment().toDate(),
          endDate: moment().toDate(),
          key: "selection",
        },
      };

      setCalendars((prev) => [...prev, newCal]);
      setTempDateValues((prev) => [
        ...prev,
        {
          startDate: moment().format("YYYY-MM-DD"),
          endDate: moment().format("YYYY-MM-DD"),
        },
      ]);

      // Then advance index
      setTimeout(() => {
        setCurrentCalendarIndex((prevIdx) => prevIdx + 1);
      }, 50);
    } else {
      // Just go to the next existing one
      setCurrentCalendarIndex((prevIdx) => prevIdx + 1);
    }
  };

  const handlePreviousCalendar = () => {
    if (currentCalendarIndex > 0) {
      setCurrentCalendarIndex((prevIdx) => prevIdx - 1);
    }
  };

  const confirmRemoveCalendar = (index) => {
    setCalendarToRemove(index);
    setAlertDeleteCalendar(true);
  };

  const executeRemoveCalendar = () => {
    if (calendarToRemove == null) return;
    const updated = calendars.filter((_, idx) => idx !== calendarToRemove);
    const updatedTemps = tempDateValues.filter(
      (_, idx) => idx !== calendarToRemove
    );

    // Adjust current index so we don't go out of bounds
    let newIndex = currentCalendarIndex;
    if (currentCalendarIndex > calendarToRemove) {
      newIndex = currentCalendarIndex - 1;
    } else if (currentCalendarIndex === calendarToRemove) {
      newIndex =
        calendarToRemove < updated.length
          ? calendarToRemove
          : calendarToRemove - 1;
    }
    setCalendars(updated);
    setTempDateValues(updatedTemps);
    setCurrentCalendarIndex(Math.max(0, newIndex));
    setAlertDeleteCalendar(false);
    setCalendarToRemove(null);
  };

  const applyCampaignNowRules = (index, nextCalendars, nextTempVals) => {
    const cal = nextCalendars[index];
    if (!cal) return;

    const startEnabled = cal.enableStartDate;
    const endEnabled = cal.enableEndDate;
    const sd = cal.dateRange.startDate; // might be null

    // Using moment() consistently
    const isToday = sd && moment(sd).isSame(moment(), "day");
    const isFuture = sd && moment(sd).isAfter(moment(), "day");

    // (1) If end date enabled but start date not, force start = today, publishNow = true
    if (endEnabled && !startEnabled) {
      cal.enableStartDate = true;
      cal.dateRange.startDate = moment().toDate();
      // Reflect that in temp values
      if (nextTempVals?.[index]) {
        nextTempVals[index].startDate = moment().format("YYYY-MM-DD");
      }
      // Force publishNow
      setPublishNow(true);
    }
    // (2) If start is enabled & exactly "today", publishNow = true
    else if (startEnabled && isToday) {
      // setPublishNow(true);
    }
    // (3) If both start & end are disabled, force start = today, publishNow = true
    else if (!startEnabled && !endEnabled) {
      cal.enableStartDate = true;
      cal.dateRange.startDate = moment().toDate();
      if (nextTempVals?.[index]) {
        nextTempVals[index].startDate = moment().format("YYYY-MM-DD");
      }
      setPublishNow(true);
    }
    // (4) If start is enabled but is a future date => publishNow = false
    else if (startEnabled && isFuture) {
      // Before forcing publishNow false, see if there's still ANY "today" calendar
      const stillHasToday = nextCalendars.some((c) => {
        if (!c.enableStartDate || !c.dateRange.startDate) return false;
        return moment(c.dateRange.startDate).isSame(moment(), "day");
      });
      if (!stillHasToday) {
        setPublishNow(false);
      }
    }

    return { nextCalendars, nextTempVals };
  };

  return (
    <>
      <Formik
        initialValues={{
          name: data?.name || "",
          client: data?.client || "",
          observations: data?.observations || "",
          manualSelection: data?.schedules && data.schedules.length > 1,
          // calendars: calendarList,
          videos: videos,
        }}
        validationSchema={validationSchema}
        innerRef={formikRef}
        onSubmit={handleFormikSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          setFieldValue,
          isSubmitting,
        }) => (
          <Form style={styles.container}>
            <div style={styles.leftSection}>
              <TextField
                sx={styles.select}
                name="name"
                label="Campaign Name"
                variant="outlined"
                fullWidth
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />

              <TextField
                sx={styles.select}
                name="client"
                label="Client Name"
                variant="outlined"
                fullWidth
                value={values.client}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.client && Boolean(errors.client)}
                helperText={touched.client && errors.client}
              />

              <TextField
                sx={styles.select}
                name="observations"
                label="Observations"
                variant="outlined"
                fullWidth
                multiline
                rows={3}
                value={values.observations}
                onChange={handleChange}
                onBlur={handleBlur}
              />

              <div className="schedule-container">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "24px",
                    marginTop: "24px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "16px",
                      position: "relative",
                      zIndex: 2,
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        fontFamily: "SeagoeUI",
                        color: "#ff4757",
                        textTransform: "uppercase",
                        letterSpacing: "1.5px",
                      }}
                    >
                      Timeline
                    </span>
                    <h2
                      style={{
                        fontSize: "2.5rem",
                        fontFamily: "SeagoeUIBold",
                        color: "#2c3e50",
                        margin: 0,
                        position: "relative",
                      }}
                    >
                      Schedule
                      <div
                        style={{
                          position: "absolute",
                          bottom: "-4px",
                          left: 0,
                          width: "40%",
                          height: "4px",
                          background: "#ff4757",
                          borderRadius: "2px",
                        }}
                      />
                    </h2>
                  </div>
                  <div
                    style={{
                      height: "2px",
                      flex: 1,
                      marginLeft: "24px",
                      background:
                        "linear-gradient(to right, rgba(255, 71, 87, 0.5) 0%, transparent 100%)",
                    }}
                  />
                </div>

                <div
                  style={{
                    background: "rgba(255,64,129,0.05)",
                    padding: "1.25vw 1.25vw 1.25vw 1.25vw",
                    borderRadius: "12px",
                    display: "flex",
                    flexDirection: "column",
                    rowGap: "1vw",
                  }}
                >
                  {/* <FormControlLabel
                    control={
                      <PinkSwitch
                        checked={manualSelection}
                        onChange={(e) =>
                          handleManualSelectionChange(e.target.checked)
                        }
                      />
                    }
                    label={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            color: "#2c3e50",
                          }}
                        >
                          Multiple Calendar Selection
                        </span>
                        <span
                          style={{
                            fontSize: "12px",
                            color: "#666",
                          }}
                        >
                          Enable to manage multiple calendars manually
                        </span>
                      </div>
                    }
                    sx={{
                      margin: 0,
                      alignItems: "center",
                      gap: 2,
                    }}
                  /> */}
                  <FormControlLabel
                    control={
                      <PinkSwitch
                        checked={publishNow}
                        onChange={(event, checkedValue) =>
                          handlePublishNowChange(checkedValue)
                        }
                      />
                    }
                    label={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                        }}
                      >
                        <span style={{ fontSize: "0.8vw", color: "#2c3e50" }}>
                          Publish campaign now
                        </span>
                      </div>
                    }
                    sx={{
                      margin: 0,
                      alignItems: "center",
                      gap: 2,
                    }}
                  />
                  <Divider
                    sx={{
                      height: "1px",
                      border: "none",
                      backgroundImage:
                        "linear-gradient(to right, rgba(255, 71, 87, 0.5) 0%, transparent 100%)",
                    }}
                  />
                  <FormControlLabel
                    control={
                      <PinkSwitch
                        checked={campaignNeverEnds}
                        onChange={(e) =>
                          handleCampaignNeverEndsChange(e.target.checked)
                        }
                      />
                    }
                    label={
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "4px",
                        }}
                      >
                        <span style={{ fontSize: "0.8vw", color: "#2c3e50" }}>
                          Campaign never ends
                        </span>
                      </div>
                    }
                    sx={{
                      margin: 0,
                      alignItems: "center",
                      gap: 2,
                    }}
                  />
                </div>
              </div>

              <h3 className="calendar-title">DATE RANGE</h3>
              <div className="calendar-wrapper">
                <div className="calendar-transition-container">
                  {calendars.map((calendar, index) => (
                    <div
                      key={calendar.id}
                      className={`calendar-slide ${
                        index === currentCalendarIndex ? "active" : ""
                      }`}
                      style={{ zIndex: index === currentCalendarIndex ? 1 : 0 }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1rem",
                        }}
                      >
                        <div style={{ display: "flex", gap: "2rem" }}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.5rem",
                            }}
                          >
                            {/* <Checkbox
                              size="small"
                              color="black"
                              checked={calendar.enableStartDate}
                              onChange={(e) =>
                                handleToggleStartDate(index, e.target.checked)
                              }
                            />
                            <label style={{ fontSize: "0.8rem" }}>
                              Enable Start Date
                            </label> */}
                          </div>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "0.5rem",
                            }}
                          >
                            {/* <Checkbox
                              size="small"
                              color="black"
                              checked={calendar.enableEndDate}
                              onChange={(e) =>
                                handleToggleEndDate(index, e.target.checked)
                              }
                            />
                            <label style={{ fontSize: "0.8rem" }}>
                              Enable End Date
                            </label> */}
                          </div>
                        </div>

                        <div style={{ display: "flex", gap: "2rem" }}>
                          {calendar.enableStartDate && (
                            <TextField
                              style={{ display: publishNow ? "none" : "block" }}
                              type="date"
                              sx={styles.select}
                              label="Start Date"
                              value={tempDateValues[index]?.startDate || ""}
                              onChange={(e) =>
                                handleDateInputChange(
                                  index,
                                  "startDate",
                                  e.target.value
                                )
                              }
                              onBlur={(e) =>
                                handleDateInputBlur(
                                  index,
                                  "startDate",
                                  e.target.value
                                )
                              }
                              inputProps={{
                                min: moment().format("YYYY-MM-DD"),
                              }}
                            />
                          )}
                          {calendar.enableEndDate && (
                            <TextField
                              type="date"
                              sx={styles.select}
                              label="End Date"
                              value={tempDateValues[index]?.endDate || ""}
                              onChange={(e) =>
                                handleDateInputChange(
                                  index,
                                  "endDate",
                                  e.target.value
                                )
                              }
                              onBlur={(e) =>
                                handleDateInputBlur(
                                  index,
                                  "endDate",
                                  e.target.value
                                )
                              }
                              inputProps={{
                                min: moment().format("YYYY-MM-DD"),
                              }}
                            />
                          )}
                        </div>
                      </div>

                      {calendars.length > 1 && (
                        <Tooltip title="Delete current date range">
                          <button
                            type="button"
                            className="remove-calendar-btn"
                            onClick={() => confirmRemoveCalendar(index)}
                            style={{ marginTop: "1rem" }}
                          >
                            <MinusSquare size={20} />
                          </button>
                        </Tooltip>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              {manualSelection && (
                <div className="chevron-container">
                  <button
                    type="button"
                    className="chevron-btn"
                    onClick={handlePreviousCalendar}
                    disabled={currentCalendarIndex === 0}
                  >
                    <ChevronLeft size={24} />
                  </button>
                  <button
                    type="button"
                    className="chevron-btn"
                    onClick={handleNextCalendar}
                  >
                    <ChevronRight size={24} />
                  </button>
                </div>
              )}
              <div className="calendar-container-dates">
                {calendars.map((calendar, index) => (
                  <div
                    key={`${calendar.id}-${index}`}
                    className={`calendar-card ${
                      index === currentCalendarIndex ? "active-card" : ""
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => setCurrentCalendarIndex(index)}
                  >
                    <div className="date-range">
                      <div className="start-date">
                        {calendar.enableStartDate ? (
                          publishNow ? (
                            <div style={{ fontStyle: "italic" }}>
                              Starting now
                            </div>
                          ) : (
                            <>
                              <div className="date">
                                {moment(calendar.dateRange.startDate).format(
                                  "DD"
                                )}
                              </div>
                              <div className="month-year">
                                <div>
                                  {moment(calendar.dateRange.startDate).format(
                                    "MMM"
                                  )}
                                </div>
                                <div>
                                  {moment(calendar.dateRange.startDate).format(
                                    "YYYY"
                                  )}
                                </div>
                              </div>
                            </>
                          )
                        ) : (
                          <div style={{ fontStyle: "italic" }}>
                            No Start Date
                          </div>
                        )}
                      </div>

                      <div className="range-divider">
                        <span className="arrow">→</span>
                      </div>

                      <div className="end-date">
                        {calendar.enableEndDate ? (
                          <>
                            <div className="date">
                              {moment(calendar.dateRange.endDate).format("DD")}
                            </div>
                            <div className="month-year">
                              <div>
                                {moment(calendar.dateRange.endDate).format(
                                  "MMM"
                                )}
                              </div>
                              <div>
                                {moment(calendar.dateRange.endDate).format(
                                  "YYYY"
                                )}
                              </div>
                            </div>
                          </>
                        ) : (
                          <div style={{ fontStyle: "italic" }}>No End Date</div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              <button type="submit" className="add-video-button">
                Choose your devices →
              </button>
            </div>

            <div style={styles.rightSection}>
              <div
                style={{
                  marginBottom: "20px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "24px",
                    position: "relative",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <span
                      style={{
                        fontSize: "14px",
                        color: "#ff4757",
                        fontFamily: "SeagoeUIBold",
                        textTransform: "uppercase",
                        letterSpacing: "2px",
                        opacity: 0.9,
                      }}
                    >
                      Media Library
                    </span>
                    <h2
                      style={{
                        fontSize: "2.5rem",
                        fontFamily: "SeagoeUIBold",
                        color: "#2c3e50",
                        margin: 0,
                        position: "relative",
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                      }}
                    >
                      Upload Videos
                      <span
                        style={{
                          fontSize: "14px",
                          color: "#94a3b8",
                          fontWeight: "500",
                          padding: "6px 12px",
                          background: "rgba(255,64,129,0.08)",
                          borderRadius: "20px",
                          letterSpacing: "0.5px",
                        }}
                      >
                        MP4 supported
                      </span>
                    </h2>
                  </div>

                  <div
                    style={{
                      height: "2px",
                      flex: 1,
                      background:
                        "linear-gradient(to right, rgba(255, 71, 87, 0.5) 0%, transparent 100%)",
                      marginLeft: "24px",
                    }}
                  />
                </div>

                <CustomButton
                  text={"Select Media Files"}
                  onClick={() => setIsModalOpen(true)}
                  style={{
                    marginTop: "22px",
                  }}
                />
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: "16px",
                }}
              >
                {values.videos.length ? (
                  <>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                        marginBottom: "20px",
                        padding: "0 12px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13px",
                          fontFamily: "SeagoeUIBold",
                          color: "#94a3b8",
                          textTransform: "uppercase",
                          letterSpacing: "1px",
                        }}
                      >
                        Selected Files
                      </span>
                      <div
                        style={{
                          height: "1px",
                          flex: 1,
                          background:
                            "linear-gradient(to right, #e2e8f0 0%, transparent 100%)",
                        }}
                      />
                      <span
                        style={{
                          padding: "4px 12px",
                          borderRadius: "20px",
                          fontSize: "13px",
                          color: "#64748b",
                          background: "rgba(226,232,240,0.5)",
                        }}
                      >
                        {values.videos.length} videos
                      </span>
                    </div>

                    {values.videos.map((video, index) => (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                          padding: "10px 20px",
                          background: "white",
                          borderRadius: "16px",
                          boxShadow: "0 4px 20px rgba(148,163,184,0.05)",
                          transition: "all 0.3s ease",
                          border: "1px solid rgba(226,232,240,0.8)",
                          position: "relative",
                          overflow: "hidden",
                        }}
                        onMouseEnter={(e) => {
                          e.currentTarget.style.transform = "translateY(-2px)";
                          e.currentTarget.style.boxShadow =
                            "0 8px 30px rgba(148,163,184,0.12)";
                        }}
                        onMouseLeave={(e) => {
                          e.currentTarget.style.transform = "translateY(0)";
                          e.currentTarget.style.boxShadow =
                            "0 4px 20px rgba(148,163,184,0.05)";
                        }}
                      >
                        <div
                          style={{
                            width: "28px",
                            height: "28px",
                            borderRadius: "8px",
                            background: "rgba(255,64,129,0.08)",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            color: "#ff4081",
                            fontSize: "14px",
                          }}
                        >
                          {index + 1}
                        </div>

                        <div
                          style={{
                            flex: 1,
                            background: "rgba(241,245,249,0.5)",
                            padding: "12px 16px",
                            borderRadius: "10px",
                            border: "1px solid rgba(226,232,240,0.6)",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <svg
                              width="18"
                              height="18"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="#64748b"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                              <polyline points="14 2 14 8 20 8" />
                            </svg>
                            <span
                              style={{
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#334155",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {video.title}.mp4
                            </span>
                          </div>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "12px",
                            padding: "8px 16px",
                            background: "rgba(241,245,249,0.5)",
                            borderRadius: "10px",
                            border: "1px solid rgba(226,232,240,0.6)",
                          }}
                        >
                          <label
                            style={{
                              fontSize: "14px",
                              color: "#64748b",
                              fontWeight: "500",
                            }}
                          >
                            Minimum displays
                          </label>
                          <TextField
                            type="number"
                            variant="outlined"
                            size="small"
                            value={video.frequency || 1}
                            onChange={(e) => {
                              const newValue = Math.max(
                                0,
                                parseInt(e.target.value) || 0
                              );
                              const updatedVideos = [...values.videos];
                              updatedVideos[index].frequency = newValue;
                              setFieldValue("videos", updatedVideos);
                            }}
                            inputProps={{
                              min: 0,
                              style: {
                                textAlign: "center",
                                padding: "8px",
                                fontSize: "14px",
                                fontWeight: "500",
                                color: "#334155",
                              },
                            }}
                            sx={{
                              width: "90px",
                              "& .MuiOutlinedInput-root": {
                                background: "white",
                                borderRadius: "8px",
                                "& fieldset": {
                                  borderColor: "rgba(226,232,240,0.8)",
                                },
                                "&:hover fieldset": {
                                  borderColor: "#ff4081",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "#ff4081",
                                },
                              },
                            }}
                          />
                        </div>

                        <button
                          type="button"
                          style={{
                            border: "none",
                            background: "none",
                            padding: "8px",
                            cursor: "pointer",
                            borderRadius: "8px",
                            transition: "all 0.2s ease",
                            color: "#64748b",
                          }}
                          onMouseEnter={(e) => {
                            e.currentTarget.style.background =
                              "rgba(239,68,68,0.08)";
                            e.currentTarget.style.color = "#ef4444";
                          }}
                          onMouseLeave={(e) => {
                            e.currentTarget.style.background = "none";
                            e.currentTarget.style.color = "#64748b";
                          }}
                          onClick={() => {
                            const updatedVideos = values.videos.filter(
                              (_, i) => i !== index
                            );
                            setFieldValue("videos", updatedVideos);
                          }}
                        >
                          <MinusSquare size={20} />
                        </button>
                      </div>
                    ))}
                  </>
                ) : (
                  <div
                    style={{
                      marginTop: "10px",
                      padding: "32px",
                      textAlign: "center",
                      color: "#64748b",
                      fontSize: "15px",
                      background: "rgba(241,245,249,0.5)",
                      borderRadius: "16px",
                      border: "1px dashed rgba(148,163,184,0.4)",
                    }}
                  >
                    No videos selected yet
                  </div>
                )}
              </div>

              <AddVideoDialog
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onSubmit={(videoArray) => {
                  const newVideos = [...values.videos];
                  videoArray.forEach((v) => {
                    if (v.frequency === undefined) v.frequency = 1;
                  });
                  setFieldValue("videos", [...newVideos, ...videoArray]);
                  setIsModalOpen(false);
                }}
                onSelect={(videoArray) => {
                  const newVideos = [...values.videos];
                  videoArray.forEach((v) => {
                    if (v.frequency === undefined) v.frequency = 1;
                  });
                  setFieldValue("videos", [...newVideos, ...videoArray]);
                  setIsModalOpen(false);
                }}
                instantUpload={false}
                isCampaign={true}
              />
            </div>
          </Form>
        )}
      </Formik>
      <AlertModal
        open={alertDeleteCalendar}
        handleClose={() => {
          setAlertDeleteCalendar(false);
          setCalendarToRemove(null);
        }}
        text="Are you sure you want to delete this calendar?"
        handleYes={executeRemoveCalendar}
      />
    </>
  );
}
